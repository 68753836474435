import { auth, db } from '../../firebase.js';
import queryString from 'query-string';

export default async function register(data, successCallback) {
    const linkParams = queryString.parse(window.location.search);

    let userCredential = await auth.createUserWithEmailAndPassword(data.email, data.password);

    await db.collection("users").doc(auth.currentUser.uid).set({
        email: data.email,
        fName: data.fName,
        lName: data.lName
    });

    await userCredential.user.sendEmailVerification();

    if(linkParams.callback) {
        linkParams.callback === 'onboarding'
        ? window.location.href =`/${linkParams.callback}?step=1`
        : linkParams.key
            ? window.location.href =`/auth/${linkParams.callback}?key=${linkParams.key}`
            : window.location.href =`/${linkParams.callback}`
    };

    return true;
};