import React, {useState, useEffect, useContext, createContext} from 'react';
import {UserContext} from '../providers/UserProvider';

import Dashboard from './dashboard/Dashboard';
import Loader from '../common/components/loader/Loader';
import {BrowserRouter, Route} from 'react-router-dom';
import { prepareDashboard } from './dashboard/functions';
import Apps from './apps/Apps';

// Create an OrgContext
export const OrgContext = createContext({ org: null });

export default function Secure() {

    /* Check the user is logged in before doing anything else */

    const user = useContext(UserContext).user;
    const authReady = useContext(UserContext).ready;

    /* States */

    const [state, setState] = useState({
        data: null,
        currentOrg: null,
    });

    // Get ready to go - this runs every time the user changes to make sure I only get the correct information
    // This has recently changed

    useEffect(() => {
        async function getData() {
            if(user != null && authReady) {
                let data = await prepareDashboard({uid: user.uid});
    
                if(data == null) {
                    window.location.href = '/auth/begin'
                } else {
                    setState({
                        ...state,
                        data: data,
                        currentOrg: data.org,
                    });
                }
            } else if(user === null && authReady) {
                window.location.href = '/auth/login?callback=dashboard';
            }
        };

        getData();
        
    }, [user]);

    return (
    <OrgContext.Provider value={{
        data: state.data,
        currentOrg: state.currentOrg
    }}>
        {state.data != null
        ?   <BrowserRouter>
            <Route path="/secure/dashboard" render={() => <Dashboard/>}/>
            <Route path="/secure/apps" render={() => <Apps/>}/>
        </BrowserRouter>
        :   <Loader/>}
    </OrgContext.Provider>
    );
}