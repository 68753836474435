import React from 'react';
import './Loader.scss';

export default function Loader() {
    return (
      <div className={`dg-old-loader`}>
          <img src={'/my-dugood.svg'} className={'dg-logo'} alt={'My Dugood'}/>
          <img src={'/loader-2.gif'} className={'dg-loading'} alt={'Loading'}/>
      </div>
    );
}