import React from 'react';
import './StartButton.scss';

export default function StartButton({title, submit, onClick, loading, className, disabled}) {
    return (
      <button disabled={disabled} type={submit ? 'submit' : ''} className={`dg-start-button ${loading ? 'loading' : ''} ${className ? className : ''}`} onMouseDown={onClick}>
        <div className="flex">
            <p>{title}</p>
            {loading
            ? <span className="material-icons">refresh</span>
            : <></>}
            
        </div>
      </button>
    );
}