import Login from './views/Login';
import Reset from './views/Reset';
import Register from './views/Register';
import Complete from './views/Complete';
import Confirm from './views/Confirm';
import Verify from './views/Verify';
import Begin from './views/Begin';
import CollectorComplete from './views/CollectorComplete';
import {BrowserRouter, Route} from 'react-router-dom';


function Auth(user) {
  return (
    <BrowserRouter>
      
      <Route path="/auth/reset" component={Reset}/>
      <Route path="/auth/register" component={Register}/>
      <Route path="/auth/complete" component={Complete}/>
      <Route path="/auth/collector-complete" component={CollectorComplete}/>
      <Route path="/auth/confirm" component={Confirm}/>
      <Route path="/auth/verify" component={Verify}/>
      <Route path="/auth/begin" component={Begin}/>
      <Route path="/auth/login" component={Login}/>
      
    </BrowserRouter>
  );
}

export default Auth;
