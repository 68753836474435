import React from 'react';
import Page from '../../common/components/page/Page';

import '../../common/views/NotFound/NotFound.scss';

export default function Begin() {
    
    return (
        <Page className={'dg-404'}>
            <img src={'/illus/illus-404.svg'} alt={''}/>
            <h1>You don't own any organisations</h1>
            <p>To access your Dashboard, you first need to be an administrator of an organisation. Either ask a Dugood Admin at your organisation to invite you, or <a href={'/onboarding'}> register your own.</a></p>
        </Page> 
    )
}