import React, {useContext, useEffect, useState} from 'react';
import { OrgContext } from '../../Secure';
import { UserContext } from '../../../providers/UserProvider';
import NewQRForm from '../components/forms/NewQRForm';
import { sleep } from '../functions/sleep';
import Table from '../components/table/Table';
import Empty from '../components/empty/Empty';
import { createQRCode } from '../functions';
import {Row, Col, Alert} from 'react-bootstrap';
import moment from 'moment';
import { storage } from '../../../firebase.js';
import CampaignsListItem from '../components/campaignsListItem/CampaignsListItem';
const {List, Cta, Modal, Toast} = require('@dugood/dds');

export default function ScanToGive(props) {

    const data = useContext(OrgContext);

    let qrCodes = null;

    if(data.data.qrCodes !== undefined) {
        qrCodes = data.data.qrCodes
    }

    const currentOrg = data.currentOrg;



    const [state, setState] = useState({
        success: false,
        tableData: qrCodes,
        modalOpen: false,
        gaModalOpen: false,
    })

    async function handleNewQR(response) {
        const id = currentOrg.orgId + '001' + moment().unix().toString(16);

        await createQRCode({
            orgId: currentOrg.orgId,
            amount: response.amount ? response.amount : null,
            friendly: response.friendly,
            id: id,
            created: moment().toDate(),
            giftaid: response.giftaid,
        });

        let newData = {
            friendly: response.friendly,
            id: id,
            organisation: currentOrg.orgId,
            tally: 0,
            type: '001',
            created: moment().toDate(),
        }

        setState(prev => ({
            success: true,
            tableData: [newData, ...prev.tableData],
            modalOpen: false,
        }));

        await sleep(8000);
        
        setState(prev => ({
            ...prev,
            success: false,
        }));

    }


    function openNewQRModal() {
        setState({
            ...state,
            modalOpen: true,
        });
    }

    function closeNewQRModal() {
        setState({
            ...state,
            modalOpen: false,
        })
    }

    return (
        <div>
            <Cta
                icon={'Plus'}
                title={'New campaign'}
                color={'purple'}
                onClick={openNewQRModal}
                body={'Donations get linked to campaigns, so you can track performance.'}
            />
            <Cta
                icon={'HelpCircle'}
                title={'Learn more'}
                color={'orange'}
                onClick={() => {window.location.href='https://www.dugood.co.uk/help/how-to-use-qr-codes-to-track-performance'}}
                body={'Visit the Dugood website to learn more about how campaigns work.'}
            />

            {state.tableData.length > 0 ?
                <List items={state.tableData} component={CampaignsListItem} perPage={10} />
                :
                <Empty icon={'payments'} title={'No campaigns yet! Create your first.'}/>
            }
            
            

            <Toast
                type={'success'}
                visible={state.success}
                title={'Campaign created'}
                body={'Click the QR code icon next to the campaign in the list to download and share your campaign.'}
            />

            <Modal
                title={'New campaign'}
                visible={state.modalOpen}
                handleClose={closeNewQRModal}
            >
                <NewQRForm
                    handleSubmit={(values) => handleNewQR(values)}
                    orgGiftAid={currentOrg.giftaid}
                />
            </Modal>

            
        </div>
    );
}