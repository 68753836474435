import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCe8DAdLUMr9qrdaFNWGy5M8HQa3AkmxeI",
    authDomain: "dugood-2020.firebaseapp.com",
    databaseURL: "https://dugood-2020.firebaseio.com",
    projectId: "dugood-2020",
    storageBucket: "dugood-2020.appspot.com",
    messagingSenderId: "859719906832",
    appId: "1:859719906832:web:368e43ca76feb4d13efc95",
    measurementId: "G-H01Z4RYR9V"
};

const secondaryConfig = {
    databaseURL: "https://dugood-dev.firebaseio.com",
    apiKey: "AIzaSyAwVJnLvYEcOr4nlN87vsepIUxdqeEq88g",
    authDomain: "dugood-dev.firebaseapp.com",
    projectId: "dugood-dev",
    storageBucket: "dugood-dev.appspot.com",
    messagingSenderId: "663546870811",
    appId: "1:663546870811:web:fcedb06009a8b935e3ccbe"
};

!firebase.apps.length && firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export default firebase;