import React from 'react';
import UserProvider from './providers/UserProvider';
import Footer from './common/components/footer/Footer';
import Route from './Route';

import './App.css';
import './styles/variables.scss';

function App() {

  // Once App() loads, OnAuthStateChanged is getting called, which is *then* setting
  // user correctly. Before then, it's set to null.

  // There's one render where firebase isn't available followed by one where it is.
  

  return (
    <UserProvider>
      <Route />
      <Footer/>
    </UserProvider>
    
  );
}

export default App;
