import React from 'react';
import './GPPreview.scss';

export default function GPPreview(props) {
    return (
        <div className={`dg-givingpage-preview`} style={{backgroundColor: props.brand}}>
            <div className={'title'}>
                <span className={'preamble'}>
                    Donate to support
                </span>
                <span className={'name'}>
                    {props.name}
                </span>
            </div>
            <div className={'buttons'}>
                <div className={'prompt'}>
                    How much do you want to give?
                </div>
                <div className={'buttons-row'}>
                    <div className={'gp-button'}>
                        <div className={'placeholder'}>&nbsp;</div>
                    </div>
                    <div className={'gp-button'}>
                        <div className={'placeholder'}>&nbsp;</div>
                    </div>
                    <div className={'gp-button'}>
                        <div className={'placeholder'}>&nbsp;</div>
                    </div>
                </div>
                <div className={'buttons-row'}>
                    <div className={'gp-button'}>
                        <div className={'placeholder'}>&nbsp;</div>
                    </div>
                    <div className={'gp-button'}>
                        <div className={'placeholder'}>&nbsp;</div>
                    </div>
                    <div className={'gp-button'}>
                        <div className={'placeholder'}>&nbsp;</div>
                    </div>
                </div>
            </div>
            <div className={"givesafe"}>
                <img src={"/givesafe-w.svg"} alt={"GiveSafe"}/>
                <div className={"org"}><img src={"/gs-check.svg"} alt={"Accredited"}/> <p>{props.giveSafe}</p></div>
            </div>
        </div>
    );
}