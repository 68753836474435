import React from 'react';
import './Empty.scss';

export default function TabTitle(props) {
    return (
        <div className={`dg-empty ${props.className && props.className}`}>
            <span className="material-icons">
                {props.icon}
            </span>
            <h4>{props.title}</h4>
        </div>
    );
}