import React from 'react';
import './RankingItem.scss';

export default function RankingItem(props) {
    return (
        <li key={props.rank} className={`dg-ranking-item ${props.className && props.className}`}>
            <div className={'rank'}>
                {props.rank}
            </div>
            <div className={'details'}>
                <h3>{props.title}</h3>
                <p>{props.detail} raised</p>
            </div>
        </li>
    );
}