import firebase, { db } from '../../firebase.js';

export default async function promoteCollector(org, uid) {

    try {
        await db.collection("organisations").doc(org).update({
            admins: firebase.firestore.FieldValue.arrayUnion(`/users/${uid}`),
            collectors: firebase.firestore.FieldValue.arrayRemove(`/users/${uid}`)
        });

        return Promise.resolve();
    } catch(error) {
        return Promise.reject(error);
    }
    
};