import React from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';
import MobileTableItem from '../mobileTableItem/MobileTableItem';
import './Table.scss';

export default function Table(props) {

    const [data, setData] = React.useState(props.data);

    React.useEffect(() => {
        setData(props.data)
    }, [props.data]);

    //var data = React.useMemo(() => props.data, [props.data]);
    let accessors = Object.keys(props.data[0]);

    let columns = [];

    props.columns.map((name, index) => {
        columns.push({
            Header: name,
            accessor: accessors[index]
        });

        return;
    });

    columns = React.useMemo(() => columns, []);

    let initialSort = [{id: 'sorter', desc: true}];

    let sort = React.useMemo(() => initialSort, []);

    function GlobalFilter({
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
          setGlobalFilter(value || undefined)
        }, 200)
      
        return (
            <input
                value={value || ""}
                onChange={e => {
                    e.preventDefault();
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Type to filter...`}
                style={{
                    fontSize: '1em',
                }}
            />
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
    } = useTable({columns, data, initialState: { pageIndex: 0, pageSize: 25, globalFilter: undefined, sortBy: sort, hiddenColumns: ['sorter'] }}, props.sortable && useGlobalFilter, useSortBy, usePagination)

    

    return (
        <>
        <table {...getTableProps()} className={'dg-table'}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(props.sortable && column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {props.sortable && column.isSorted
                                    ? column.isSortedDesc
                                        ? <span className="material-icons">expand_less</span>
                                        : <span className="material-icons">expand_more</span>
                                    : <span className="material-icons">unfold_more</span>
                                }
                            </th>
                        ))}
                    </tr>
                ))}
                <tr className={'dg-filter'}>
                    <th colSpan={100}>   
                        <GlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </th>
                </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row);
                    return (
                        <>
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                        </tr>
                        <MobileTableItem row={row.cells}/>
                        </>
                    )
                })}
            </tbody>
        </table>
        {data.length > pageOptions.length && (<div className="dg-pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <span className="material-icons">
                    first_page
                </span>
            </button>
            <div>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <span className="material-icons">
                        chevron_left
                    </span>
                </button>
                <p>
                    Page{' '}
                    <strong>
                        {pageIndex + 1}
                    </strong>
                    {' '}of {pageOptions.length}
                </p>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <span className="material-icons">
                        chevron_right
                    </span>
                </button>
            </div>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <span className="material-icons">
                    last_page
                </span>
            </button>
            
        </div>)}
        <div className={'dg-page-controls'}>
            <p style={{marginLeft: 0}}>
                Showing results {' '}<strong>
                    {(pageIndex) * (pageSize) + 1} 
                    {' '}to{' '}
                    {
                        ((pageIndex) * (pageSize) + 1) + (pageSize - 1) > data.length
                        ? data.length
                        : ((pageIndex) * (pageSize) + 1) + (pageSize - 1)
                    } {' '}
                </strong> of {data.length}
            </p>
            <div>
                <select
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
                >
                {[25, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize} per page
                    </option>
                ))}
                </select>
            </div>
        </div>
        </>
    );
}