import firebase, { db, auth } from '../../firebase.js';

// Data: key

export default async function collectorAcceptInvite(data) {

    let keys = await db.collection("keys").where("key", "==", data.key).limit(1).get();
    let key = null;

    if (keys.docs.length === 0) {
        throw new Error("Invalid key. This invitation may have already been redeemed.")
    } else {
        key = keys.docs[0].data();
    };

    await checkKey(key);

    await checkNoOrgs();

    await processAcceptance(key.key);

    window.location.href = '/auth/collector-complete';

};

function checkKey(key) {
    if (key.email !== auth.currentUser.email) {
        return Promise.reject("The email you are logged in using does not match the email the invitation was sent to.");
    } else {
        return Promise.resolve();
    };
}

async function checkNoOrgs() {

    let collectorsSnapshot = await db.collection("organisations").where("collectors", "array-contains", auth.currentUser.uid).get().catch((error) => {
        return Promise.reject(error);
    });

    if(!collectorsSnapshot.empty) {
        return Promise.reject("Sorry, you are already a collector for an organisation. You cannot belong to more than one organisation.")
    };

    let adminsSnapshot = await db.collection("organisations").where("admins", "array-contains", auth.currentUser.uid).get().catch((error) => {
        return Promise.reject(error);
    });

    if(!adminsSnapshot.empty) {
        return Promise.reject("Sorry, you are already an administrator for an organisation. You cannot belong to more than one organisation.")
    } else {
        return Promise.resolve();
    };
}

async function processAcceptance(key) {

    let orgs = await db.collection("organisations").where("keys", "array-contains", key).get();

    orgs.forEach((doc) => {

        // Reject if already a collector
        if(doc.data().collectors.includes(auth.currentUser.uid) || doc.data().admins.includes(auth.currentUser.uid)) {
            throw new Error("You are already a collector or an administrator for this organisation.");
        };

        doc.ref.update({
            keys: firebase.firestore.FieldValue.arrayRemove(key),
            collectors: firebase.firestore.FieldValue.arrayUnion(`/users/${auth.currentUser.uid}`),
        });
    })

    let keys = await db.collection("keys").where("key", "==", key).get();

    keys.forEach(doc => {
        doc.ref.delete();
    });

    return Promise.resolve();

}