import React, {useState, useContext} from 'react';
import {UserContext} from '../../providers/UserProvider';
import Page from '../../common/components/page/Page';
import StartButton from '../../common/components/startButton/StartButton';
import { auth } from '../../firebase.js';

import {Alert} from 'react-bootstrap';

import '../../common/views/NotFound/NotFound.scss';

export default function Verify() {

    const user = useContext(UserContext);

    if(user.emailVerified) {
        window.location.href = '/dashboard';
    }

    const [sent, setSent] = useState(false);

    function sendVerification() {
        auth.currentUser.sendEmailVerification().then(() => {
            setSent(true);
        })
    }
    
    return (
        <Page className={'dg-404'}>
            <img src={'/illus/illus-404.svg'} alt={''}/>
            <h1>Verify your email address</h1>
            <p>Please verify your email address to continue to Dugood. Check your inbox for your verification link or alternatively request a new link.</p>
            {!sent ? <StartButton title={'Resend verification email'} onClick={sendVerification}/> : <Alert variant={'success'}>Verification email sent successfully!</Alert> }
        </Page> 
    )
}