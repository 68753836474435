import Poster from './poster/Poster';
import Invoice from './invoice/Invoice';
import GiftAidDec from './giftAidDec/GiftAidDec';
import {BrowserRouter, Route} from 'react-router-dom';

function Apps(props) {
  return (
    <BrowserRouter>
      <Route path="/secure/apps/poster" render={() => <Poster/>}/>
      <Route path="/secure/apps/invoice" render={() => <Invoice/>}/>
      <Route path="/secure/apps/giftaiddec" render={() => <GiftAidDec/>}/>
    </BrowserRouter>
  );
}

export default Apps;
