import React, {useContext} from 'react';
import { OrgContext } from '../../Secure';
import FlagsDisplay from '../components/flagsDisplay/FlagsDisplay';
import Card from '../components/card/Card';
import RankingItem from '../components/rankingItem/RankingItem';
import {Row, Col} from 'react-bootstrap';
const {Cta} = require('@dugood/dds');

export default function Home(props) {

    const data = useContext(OrgContext).data;
    const orgInfo = useContext(OrgContext).currentOrg;

    return (
        <div>
            <Row>
                <Col md={12}>
                    <Cta
                        icon={'Coins'}
                        title={'See donations'}
                        color={'purple'}
                        onClick={() => props.handleChange('Donations')}
                        body={"See every donation you've recieved, and export reports."}
                    />
                    <Cta
                        icon={'QrCode'}
                        title={'Create a campaign'}
                        color={'orange'}
                        onClick={() => props.handleChange('Campaigns')}
                        body={'Create a campaign to accept donations using a QR code.'}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} lg={4}>
                    <Card className={'dg-stats'}>
                        <div className={'dg-stat'}>
                            <h4>Today</h4>
                            <p>£{String(data.stats.today)}</p>
                        </div>
                        <div className={'dg-stat'}>
                            <h4>This week</h4>
                            <p>£{String(data.stats.thisweek)}</p>                         
                        </div>
                        <div className={'dg-stat'}>
                            <h4>This year</h4>
                            <p>£{String(data.stats.yeartodate)}</p>
                        </div>
                        <div className={'dg-stat'}>
                            <h4>All time</h4>
                            <p>£{String(data.stats.alltime)}</p>
                        </div>
                        
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <FlagsDisplay flags={data.org.flags}/>
                    <Row>
                        <Col md={12} sm={12}>
                            <Card title={'Top QR codes'} className={'dg-ranking'}>
                                {data.qrCodes.sort(function(a, b) {
                                    return b.tally - a.tally;
                                }).slice(0,3).map((item, i) => {
                                    return <RankingItem
                                        rank={i + 1}
                                        title={item.friendly}
                                        detail={`£${item.tally}`}
                                    />
                                })}
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}