import React from 'react';
import {FormGroup, FormControl, FormLabel, FormText, InputGroup} from 'react-bootstrap';
import { useFormik } from "formik";
import { isNumber } from 'lodash';
import './NewQRForm.scss';
const {Input, TickBox, Button} = require('@dugood/dds');

export default function NewQRForm(props) {

    const validate = values => {
        const errors = {};
    
        if (!values.friendly) {
            errors.friendly = 'Please provide a name for this QR code.';
        };
    
        if (values.amount && isNaN(values.amount)) {
            errors.amount = 'Must be a number (please remove £ if you included it).';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            friendly: '',
            amount: '',
            setAmount: false,
            giftaid: false,
        },
        validate,
        onSubmit: (values, {resetForm}) => {
            props.handleSubmit(values);
            resetForm();
        }
    });

    return (
        <form className='dg-newqrform' onSubmit={formik.handleSubmit}>
            <Input
                value={formik.values.friendly}
                name={'friendly'}
                onChange={formik.handleChange}
                valid={!formik.errors.friendly ? true : false}
                error={formik.errors.friendly}
                label={'Campaign name'}
                hint={'Only used within My Dugood.'}
                required
            />
            {props.orgGiftAid &&
            <TickBox
                checked={formik.values.giftaid}
                onChange={formik.handleChange}
                name={'giftaid'}
                required={false}
                label={'Ask donors for Gift Aid details'}
                hint={'Donations that will be for tickets, goods or services are not eligible for Gift Aid.'}
            />
                
            }
            <TickBox
                checked={formik.values.setAmount}
                onChange={formik.handleChange}
                name={'setAmount'}
                required={false}
                label={'Pre-set the donation amount'}
            />
            {formik.values.setAmount == true &&
                <Input
                    value={formik.values.amount}
                    name={'amount'}
                    onChange={formik.handleChange}
                    valid={!formik.errors.amount ? true : false}
                    error={formik.errors.amount}
                    label={'Donation amount'}
                    hint={`You'll pay £${(((+formik.values.amount) * 0.029)+0.20).toFixed(2)} in fees. Increasing your donation to £${Math.round((+formik.values.amount + 1) * 1.1)} would only cost £${((Math.round((+formik.values.amount + 1) * 1.1) * 0.029)+0.20).toFixed(2)}.`}
                    required
                />
            }
            <Button
                type={'submit'}
                primary
                label={'Create campaign'}
                onClick={formik.handleSubmit}
                className={'dds dg-button'}
            />
        </form>
    );
}