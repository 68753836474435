import React, {useState, useRef} from 'react';
import StartButton from '../../../common/components/startButton/StartButton';
import { register } from '../../functions';
import { useFormik } from "formik";
import { functions } from '../../../firebase';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import './forms.scss';

import {FormControl, FormGroup, FormLabel, Row, Col, Alert} from 'react-bootstrap';

const {Input, InfoBlock, TickBox, Button} = require('@dugood/dds');

export default function RegisterForm({successCallback}) {

    const [error, setError] = useState(null);

    const [processing, setProcessing] = useState(false);

    const validate = values => {
        const errors = {};

        if (!values.fName) {
            errors.fName = 'Required';
        }

        if (!values.lName) {
            errors.lName = 'Required';
        }
    
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address.';
        }

        if (!values.password) {
            errors.password = 'Required';
        } else if (values.password.length < 8) {
            errors.password = 'Your password is not long enough.';
        } else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(values.password)) {
            errors.password = 'Your password does not meet the criteria.';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Passwords do not match.';
        }

        if (!values.terms) {
            errors.terms = 'Required';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            fName: '',
            lName: '',
            email: '',
            password: '',
            confirmPassword: '',
            terms: true,
        },
        validate,
        onSubmit: async values => {
            try {
                setProcessing(true);
                
                register(values).then(() => {
                    setProcessing(false);
                    successCallback(values);
                }).catch((error) => {
                    window.scrollTo(0, 0);
                    setProcessing(false);
                    setError(error);
                })

            } catch(error) {
                window.scrollTo(0, 50);
                console.log(error);
                setProcessing(false);
                setError(error);
            }
            
        }
    });

    /*async function verifyCaptcha() {
        const captchaToken = await recaptchaRef.current.executeAsync();

        recaptchaRef.current.reset();

        let verify = functions.httpsCallable('verifyCaptcha');

        const res = await verify({
            captchaToken: captchaToken
        });

        if(!res.data.success) throw new Error('Sorry, you have been caught by our anti-spam filters. Please try again.');

        return;
    }*/

    return (
        <form className="mt-4">
            {error && 
            <Row>
                <Col>
                    <InfoBlock type={'error'}>
                    {error.message}
                    </InfoBlock>
                    <p></p>
                </Col>
            </Row>
            }  
            <Row>
                <Col md={6} sm={12}>
                    <Input
                        label={'First name'}
                        name={'fName'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fName}
                        error={formik.errors.fName}
                        required
                    />
                </Col>
                <Col md={6} sm={12}>
                    <Input
                        label={'Last name'}
                        name={'lName'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lName}
                        className={formik.errors.lName && formik.touched.lName ? 'invalid' : null}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12}>
                    <Input
                        label={'Email address'}
                        type={'email'}
                        name={'email'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={formik.errors.email}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Input
                        label={'Password'}
                        name={'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        error={formik.errors.password}
                        type={'password'}
                        required
                    />
                </Col>
                <Col md={6} sm={12}>
                    <Input 
                        label={'Confirm password'}
                        name={'confirmPassword'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        error={formik.errors.confirmPassword}
                        type={'password'}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12}>
                    
                    <InfoBlock type={'info'}>
                    <p>Passwords must be at least 8 characters long. You must also include a capital letter, a number and a special character.</p>
                    </InfoBlock>
                </Col>
            </Row>
            <Row className={'d-flex align-items-centre'}>
                <Col md={12}>
                    <Button
                        primary
                        label={'Create my account'}
                        onClick={formik.handleSubmit}
                        className={'dg-button dg-button--primary margin-btm'}
                    />
                    
                    <p className={'small'}>Clicking 'Create my account' means you agree to the <a href={'https://www.dugood.co.uk/terms'}>Dugood Terms</a> and <a href={'https://www.dugood.co.uk/privacy'}>Privacy Policy</a>.</p>
                </Col>
            </Row>
        </form>
    )
}