import React from 'react';
import './Card.scss';

export default function TabTitle(props) {
    return (
        <div className={`dg-card-old ${props.className && props.className}`} id={props.id && props.id}>
            {props.title && <div className={'dg-card-old-title'}>
                {props.title}

                {props.action && <button className={'dg-action'}  onClick={props.onActionClick}>
                    {props.action}
                </button>}
            </div>}
            <div className={'dg-card-old-body'}>
                {props.children}
            </div>
        </div>
    );
}