import firebase, { db, auth } from '../../firebase.js';

export default function deleteUser(email, password) {

    const user = auth.currentUser;

    // Re-authenticate

    user.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(
        email, 
        password
    )).then(async () => {

        await deleteRecords(user);

        user.delete();

        window.location.href='/auth/login';

    });
};

async function deleteRecords(user) {
    //Delete DB record

    db.collection('users').doc(user.uid).delete();

    // Remove as collector

    const collectorSnapshot = await db.collection("organisations").where("collectors", "array-contains", `/users/${user.uid}`).get();
    
    collectorSnapshot.forEach(async (doc) => {
        await doc.ref.update({
            collectors: firebase.firestore.FieldValue.arrayRemove(`/users/${user.uid}`),
        });
    });


    // Remove as admin

    const adminSnapshot = await db.collection("organisations").where("admins", "array-contains", `/users/${user.uid}`).get();

    adminSnapshot.forEach(async (doc) => {
        await doc.ref.update({
            admins: firebase.firestore.FieldValue.arrayRemove(`/users/${user.uid}`),
        });
    })

    return;
}