import React, {useContext, useEffect} from 'react';
import { OrgContext } from '../../Secure';
import {UserContext} from '../../../providers/UserProvider';
import { default as OldCard } from '../components/card/Card';
import GPPreview from '../components/gpPreview/GPPreview';
import Empty from '../components/empty/Empty';
import {Row, Col, Form, FormGroup, FormControl, FormLabel, FormText, InputGroup, Badge, Modal, Button, Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { useFormik, Formik, Field } from "formik";

import { updateOrgSettings, getBillingPeriods } from '../functions';
import {createCollector, deleteCollector, promoteCollector} from '../../../auth/functions';

import { functions, db } from '../../../firebase.js';

const {Card} = require('@dugood/dds')

export default function OrgSettings() {
    
    const data = useContext(OrgContext).data.org;
    const donations = useContext(OrgContext).data.donations;
    const user = useContext(UserContext).user;

    const [state, setState] = React.useState({
        modal: false,
        success: null,
        people: {
            admins: [],
            collectors: []
        },
        response: {
            ...data,
        },
        billingPeriods: {},
        createCollectorState: {
            error: false,
            success: false,
            msg: null,
        }
    });

    //Get collectors and admins names

    async function collectNames() {

        let admins = null;
        let collectors = null;

        if(data.admins) {
            admins = [];
            for(let i = 0; i < data.admins.length; i++) {
                var docRef = db.doc(data.admins[i]);
                const user = await docRef.get();

                admins.push({
                    path: data.admins[i],
                    id: data.admins[i].substring(7, data.admins[i].length),
                    fName: user.data().fName,
                    lName: user.data().lName,
                })
            }
        }

        if(data.collectors) {
            collectors = [];
            for(let i = 0; i < data.collectors.length; i++) {
                var docRef = db.doc(data.collectors[i]);
                const user = await docRef.get();

                collectors.push({
                    path: data.collectors[i],
                    id: data.collectors[i].substring(7, data.collectors[i].length),
                    fName: user.data().fName,
                    lName: user.data().lName,
                })
            }
        }

        return {
            admins: admins,
            collectors: collectors
        }
    };

    const validate = values => {
        const errors = {};
    
        if (!values.email) {
            errors.email = 'Please provide your email address.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address.';
        }

        return errors;
    }

    const validateSettings = values => {
        const errors = {};
    
        if (!values.orgName) {
            errors.orgName = 'Organisation name cannot be blank';
        } else if (!/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/i.test(values.brand)) {
            errors.brand = 'This is not a valid colour code.';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate,
        onSubmit: async (values) => {
            createCollector({
                orgName: data.orgName,
                email: values.email,
                orgId: data.orgId
            }).then(() => {
                setState({
                    ...state,
                    createCollectorState: {
                        error: false,
                        success: true,
                        msg: ''
                    }
                });
            }).catch((error) => {
                setState({
                    ...state,
                    createCollectorState: {
                        error: true,
                        success: false,
                        msg: error
                    }
                });
            })
        }
    })

    function createYear(year) {
        let months = state.billingPeriods[year];

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        let monthsList = []
        for (var month in months) {
            monthsList.push(
                <li key={month}>
                    <div className={'details'}>
                        {month} {year}
                    </div>
                    <div className={'dg-table-actions'}>
                        <OverlayTrigger
                            key={`dl`}
                            placement={'auto'}
                            overlay={
                                <Tooltip id={`dl`}>
                                    Download a statement
                                </Tooltip>
                            }
                        >
                        <a href={`/secure/apps/invoice?org=${data.orgId}&period=${monthNames.indexOf(month)}${year}`} target='_blank'>
                            <button title={"Download statement"}>
                                <span className="material-icons">
                                    download
                                </span>
                            </button>
                        </a>
                        </OverlayTrigger>
                    </div>
                </li>
            )
        }
        
        return(
            <><div className={'list-section-header'}>
                {year}
            </div>

            <>{monthsList}</>
            </>
        )
    }

    async function getStripeSetupURL() {
        var getStripeSetupURL = functions.httpsCallable('collectOrganisation');

        const url = await getStripeSetupURL({
            orgId: data.orgId,
            orgName: data.orgName,
            orgNumber: data.orgNumber
        });

        window.location.href = url;
    }

    useEffect(() => {

        async function fetchData() {
            let billingPeriods = getBillingPeriods(donations);
            let names = await collectNames();
            setState({
                ...state,
                people: names,
                billingPeriods: billingPeriods,
            })
        };

        fetchData();

        
    }, []);

    return (
        <div>
            <Row>
                <Col md={12}>
                    <div className={'dg-quick-actions'}>
                        Jump to: &nbsp;
                        <a href={'#basic'}>
                            <span>
                                <span className="material-icons">settings</span>
                                Basic details
                            </span>
                        </a>
                        <a href={'#brand'}>
                            <span>
                                <span className="material-icons">brush</span>
                                Brand and display
                            </span>
                        </a>
                        <a href={'#stripe'}>
                            <span>
                                <span className="material-icons">payment</span>
                                Stripe
                            </span>
                        </a>
                        <a href={'#collectors'}>
                            <span>
                                <span className="material-icons">supervisor_account</span>
                                Admins and collectors
                            </span>
                        </a>
                        <a href={'#fees'}>
                            <span>
                                <span className="material-icons">payments</span>
                                Fees
                            </span>
                        </a>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} id={'alert'}>
                    {state.success === true && <Alert variant={'success'}>
                        Settings updated successfully. Changes will appear next time you load My Dugood.
                    </Alert>}

                    {state.success === false && <Alert variant={'error'}>
                        Something went wrong updating your settings. Please try again later.
                    </Alert>}
                </Col>
            </Row>
            {(data.promoStatus.entitlement !== 0 && data.promoStatus.entitlement !== data.promoStatus.used) &&
                <Row>
                    <Col lg={12}>
                        <Card className={'dg-card margin-btm'}>
                            <h4>Collect up to £{data.promoStatus.entitlement} without paying fees</h4>
                            <p>You've got <b>£{data.promoStatus.entitlement - data.promoStatus.used}</b> remaining of your free allowance.</p>
                        </Card>
                    </Col>
                </Row>
                
            }

            <Formik 
                initialValues={{
                    orgName: data.orgName,
                    giftaid: data.giftaid,
                    brand: data.brand,
                }}
                validate={validateSettings}
                onSubmit={values => {
                    updateOrgSettings(data.orgId, values).then(() => {
                        window.scrollTo(0, 50);
                        setState({
                            ...state,
                            success: true
                        });
                        return;
                    }).catch((error) => {
                        setState({
                            ...state,
                            success: false
                        });
                        return;
                    })
                }}
                className={'dg-form form'}
            >
            {props => (
            <form onSubmit={props.handleSubmit}>
            <Row>
                <Col md={5}>
                    <OldCard title={"Basic details"} id={'basic'}>
                        <FormGroup>
                            <FormLabel>Organisation name</FormLabel>
                            <FormControl
                                value={state.response.orgName}
                                onChange={props.handleChange}
                                className={props.errors.orgName && 'invalid'}
                                type={'text'}
                                name={'orgName'}
                            />
                            {props.errors.orgName ? <div className={'validation'}>{props.errors.orgName}</div> : null}
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>GiveSafe ID</FormLabel>
                            <FormControl
                                placeholder={data.givesafe}
                                name={'friendly'}
                                readOnly
                            />
                            <FormText>Your organisation's unique public-facing identifier on donation platforms.</FormText>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Organisation ID</FormLabel>
                            <FormControl
                                placeholder={data.orgId}
                                name={'friendly'}
                                readOnly
                            />
                            <FormText>Your organisation's unique identifier across the Dugood platform.</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Field name={'giftaid'} type={'checkbox'} className={'mr-2'}/>
                            <label title>
                                Collect Gift Aid details from donors
                            </label>
                        </FormGroup>
                        <input type={'submit'} value={'Save settings'}/>
                    </OldCard>
                    <OldCard title={"Brand and display"} id={'brand'}>
                        <FormGroup>
                            <FormLabel>Brand colour</FormLabel>
                            <InputGroup className={'mb-2 dg-colorpicker'} >
                                <FormControl
                                    type={'color'}
                                    required
                                    name={'brand'}
                                    onChange={props.handleChange}
                                    value={props.values.brand}
                                    className={props.errors.brand && 'invalid'}
                                />
                                <FormControl
                                    name={'brand'}
                                    onChange={props.handleChange}
                                    value={props.values.brand}
                                    className={props.errors.brand && 'invalid'}
                                />
                            </InputGroup>
                            
                            {props.errors.brand ? <div className={'validation'}>{props.errors.brand}</div> : null}
                        </FormGroup>
                        <input type={'submit'} value={'Save settings'}/>
                    </OldCard>
                    <OldCard title={"Stripe"} id={'stripe'}>
                        {data.stripe ?
                            <>
                                <p>Your organisation is successfully connected to Stripe. <br/> Go there to manage your payouts.</p>

                                <a class={'btn'} href={'https://dashboard.stripe.com/login'}>
                                Stripe dashboard
                                </a>
                            </>
                        :
                            <>
                                <p className={'text-danger'}>Your organisation is not connected to Stripe. <br /> You cannot take donations until you connect your account.</p>

                                <button onClick={getStripeSetupURL}>
                                    Stripe dashboard
                                </button>
                            </>
                        }
                    </OldCard>
                </Col>
                <Col md={7}>
                    <OldCard title={"Giving Page preview"} className={'sticky'}>
                        <GPPreview 
                            brand={props.values.brand}
                            name={props.values.orgName}
                            giveSafe={data.givesafe}
                        />
                    </OldCard>
                </Col>
            </Row>
            </form>
            )}
            </Formik>
            <Row id={'collectors'} className={'mt-5'}>
                <Col md={12} className={'mb-3'}>
                    <h4 className={'text-secondary'}>People</h4>
                </Col>
                <Col md={6} sm={12}>
                    <OldCard title={'Organisation administrators'}>
                        {state.people.admins !== null
                        ?  <ul className={'dg-users-list'}>
                            {state.people.admins.map((admin, i) => { return(
                                <li key={i}>
                                    <div className={'details'}>
                                        <h5>{admin.fName} {admin.lName}
                                        {admin.id === user.uid && <Badge pill className={'bg-primary text-white margin-left'}>YOU</Badge>}</h5>
                                    </div>
                                    <div className={'actions'}>
                                        {admin.id !== user.uid &&
                                            <div className={'dg-table-actions'}>
                                                <OverlayTrigger
                                                    key={`${admin.id}-delete`}
                                                    placement={'auto'}
                                                    overlay={
                                                        <Tooltip id={`${admin.id}-delete`}>
                                                        Remove
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button title={"Remove"} onClick={
                                                        () => deleteCollector(data.orgId, admin.id, true).then(() => {
                                                            let splicedArray = state.people.admins;
                                                            splicedArray.splice(
                                                                state.people.admins.indexOf(admin),
                                                                1
                                                            );
                                                            setState({
                                                                ...state,
                                                                success: true,
                                                                people: {
                                                                    ...state.people,
                                                                    admins: splicedArray
                                                                }
                                                            });
                                                            return;
                                                        }).catch((error) => setState({
                                                            ...state,
                                                            success: false,
                                                        }))}
                                                    >
                                                        <span className="material-icons">
                                                        clear
                                                        </span>
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                        }
                                    </div>
                                </li>
                            )})}
                            </ul>
                        :   <Empty
                                title={'No administrators. Please contact Dugood Support as soon as possible.'}
                                icon={'admin_panel_settings'}
                            />}
                       
                        
                    </OldCard>
                </Col>
                <Col md={6} sm={12}>
                    <OldCard title={'Collectors'}
                        action={'Add new'}
                        onActionClick={() => {setState({...state, modal: true})}}
                    >
                        {state.people.collectors !== null
                        ?  <ul className={'dg-users-list'}>
                            {state.people.collectors.map((collector, i) => { return(
                                <li key={i}>
                                    <div className={'details'}>
                                        <h5>
                                        {collector.fName} {collector.lName}
                                        {collector.id === user.uid && <Badge pill className={'bg-primary text-white margin-left'}>YOU</Badge>}</h5>
                                    </div>
                                    <div className={'actions'}>
                                        {collector.id !== user.uid &&
                                            <div className={'dg-table-actions'}>
                                                <OverlayTrigger
                                                    key={`${collector.id}-promote`}
                                                    placement={'auto'}
                                                    overlay={
                                                        <Tooltip id={`${collector.id}-promote`}>
                                                        Make administrator
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button title={"Make administrator"} onClick={
                                                        () => promoteCollector(data.orgId, collector.id).then(() => {
                                                            let splicedArray = state.people.collectors;
                                                            splicedArray.splice(
                                                                state.people.collectors.indexOf(collector),
                                                                1
                                                            );
                                                            setState({
                                                                ...state,
                                                                success: true,
                                                                people: {
                                                                    collectors: splicedArray,
                                                                    admins: state.people.admins.concat([collector])
                                                                }
                                                            });
                                                            
                                                        }).catch((error) => setState({
                                                            ...state,
                                                            success: false
                                                        }))}
                                                        >
                                                        <span className="material-icons">
                                                        add_moderator
                                                        </span>
                                                    </button>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key={`${collector.id}-delete`}
                                                    placement={'auto'}
                                                    overlay={
                                                        <Tooltip id={`${collector.id}-delete`}>
                                                        Remove
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button title={"Remove"} onClick={
                                                        () => deleteCollector(data.orgId, collector.id, false).then(() => {
                                                            let splicedArray = state.people.collectors;
                                                            splicedArray.splice(
                                                                state.people.collectors.indexOf(collector),
                                                                1
                                                            );
                                                            setState({
                                                                ...state,
                                                                success: true,
                                                                people: {
                                                                    ...state.people,
                                                                    collectors: splicedArray
                                                                }
                                                            })
                                                        }).catch((error) => setState({
                                                            ...state,
                                                            success: false,
                                                        }))}
                                                    >
                                                        <span className="material-icons">
                                                        clear
                                                        </span>
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                        }
                                    </div>
                                </li>
                            )})}
                            </ul>
                        :   <Empty
                                title={'No collectors yet, but administrators can also collect donations.'}
                                icon={'face'}
                            />}
                    </OldCard>
                </Col>
            </Row>
            <Row className={'mt-5'}>
                <Col md={12} className={'mb-3'}>
                    <h4 className={'text-secondary'}>Fees and billing</h4>
                </Col>
                <Col md={12} id={'fees'}>
                    <OldCard title={'Statement of fees'} loading={state.billingPeriods !== {}}>
                        <Alert variant={'info'}>
                            Dugood and Stripe fees are deducted directly from each donation. You do not need to provide card details or make any payments to cover fees.
                        </Alert>

                        {state.billingPeriods !== {} && <ul className={'dg-users-list'}>
                            {Object.keys(state.billingPeriods).map((year, i) => {
                                
                                return(
                                    createYear(year)
                                )
                            })}
                            
                        </ul>}
                    </OldCard>
                </Col>
            </Row>

            <Modal show={state.modal} onHide={() => {setState({...state, modal: false})}} className={'dg-modal'}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new collector</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Enter the email address of the person you want to invite.</p>
                    <p>If they don't have a Dugood account, they will be asked to create one. </p>
                    <Form className={'dg-form'} onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FormLabel>Email address</FormLabel>
                            <FormControl
                                name={'email'}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                className={formik.errors.email && 'invalid'}
                                type={'text'}
                            />
                            {formik.errors.email ? <div className={'validation'}>{formik.errors.email}</div> : null}
                        </FormGroup>
                        <Button variant="primary" type={'submit'}>
                            Send invitation
                        </Button>
                    </Form>
                    {state.createCollectorState.error && <Alert className={'mt-4'} variant={'error'}>
                        {state.createCollectorState.msg}
                    </Alert>}
                    {state.createCollectorState.success && <Alert className={'mt-4'} variant={'success'}>
                        This person has been invited to collect for your organisation. They have been emailed a link to accept the invitation. <br/>
                        The invitation will not tell them which organisation has invited them, so you should tell them you have sent the invite.
                    </Alert>}
                    
                </Modal.Body>
            </Modal>
        </div>
    );
}