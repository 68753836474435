import firebase, { db } from '../../firebase.js';

export default async function deleteCollector(org, uid, admin) {
    if(admin) {
        db.collection("organisations").doc(org).update({
            admins: firebase.firestore.FieldValue.arrayRemove(`/users/${uid}`)
        }).then(() => {
            return Promise.resolve();
        });
    } else {
        db.collection("organisations").doc(org).update({
            collectors: firebase.firestore.FieldValue.arrayRemove(`/users/${uid}`)
        }).then(() => {
            return Promise.resolve();
        });
    }
};