import React, {useState, useEffect, useContext} from 'react';
import { TabContainer, TabContent, TabPane, Tab } from 'react-bootstrap';

import '../Onboarding.scss';
const {Row,Col,FlowScreen,Progress,Loader,Button,LargeButton} = require('@dugood/dds');

export default function OnboardingComplete() {

    return (
    <div className={'dds'}>
        <FlowScreen
            title={`Start fundraising for free`}
            logoUrl={'/logo-c.svg'}
        >
            <Tab.Container activeKey={'Complete'}>
                <Tab.Content className={'dg-tabs'}>
                    <h3>All done - you're ready to go</h3>
                    <p>All set! Your account has been created and your bank account linked.</p>
                    <p>Time to create your first campaign and get fundraising.</p>

                    <a href={'/secure/dashboard'}>
                        <Button
                            primary
                            flex
                            label={'Take me to the dashboard'}
                        />
                    </a>

                    <h4 className={'dg-mt'}>Learn more before you get stuck in</h4>

                    <a href={"https://www.dugood.co.uk/start"} target={'_blank'}>
                        <LargeButton title={'Learn the ropes'}
                            body={"Get a tour of Dugood"}
                            
                        />
                    </a>
                    
                    <a href={'https://www.dugood.co.uk/help/how-to-display-qr-codes'} target={'_blank'}>
                        <LargeButton title={'Fundraising advice'}
                            body={"Get our bright ideas"}
                        />
                    </a>
                    
                </Tab.Content>
            </Tab.Container>
        </FlowScreen>
        
    </div>
    );
}