import React from 'react';
import Page from '../../components/page/Page';

import './NotFound.scss';

export default function NotFound() {
    
    return (
        <Page className={'dg-404'}>
            <img src={'/illus/illus-404.svg'} alt={''}/>
            <h1>Page not found</h1>
            <p>The page you requested could not be found. Try <a href={'/dashboard'}>returning to your dashboard</a> to find what you're looking for.</p>
        </Page>
    )
}