import { auth } from '../../firebase.js';

export default async function reset(data) {
    auth.sendPasswordResetEmail(data.email)
    .then(() => {
        return Promise.resolve()
    })
    .catch((error) => {
        console.log(error);
        return Promise.reject({
            code: error.code,
            message: error.message,
        });
    });
};