import React, {useState, useRef} from 'react';
import Page from '../../common/components/page/Page';
import Container from '../../common/components/container/Container';
import PageTitle from '../../common/components/pageTitle/PageTitle';
import StartButton from '../../common/components/startButton/StartButton';
import {register} from '../functions';
import { useFormik } from "formik";
import { functions } from '../../firebase';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import {FormControl, FormGroup, FormLabel, Row, Col, Alert} from 'react-bootstrap';

export default function Register() {

    const recaptchaRef = useRef(null);

    const [error, setError] = useState(null);

    const [processing, setProcessing] = useState(false);

    const validate = values => {
        const errors = {};

        if (!values.fName) {
            errors.fName = 'Please provide your first name.';
        }

        if (!values.lName) {
            errors.lName = 'Please provide your last name.';
        }
    
        if (!values.email) {
            errors.email = 'Please provide your email address.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address.';
        }

        if (!values.password) {
            errors.password = 'Please provide a password.';
        } else if (values.password.length < 8) {
            errors.password = 'Your password is not long enough.';
        } else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(values.password)) {
            errors.password = 'Your password does not meet the criteria.';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Please confirm your password.';
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Passwords do not match.';
        }

        if (!values.terms) {
            errors.terms = 'You must accept the terms and conditions.';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            fName: '',
            lName: '',
            email: '',
            password: '',
            confirmPassword: '',
            terms: false,
        },
        validate,
        onSubmit: async values => {
            try {
                setProcessing(true);

                await verifyCaptcha();
                
                register(values).then(() => {
                    setProcessing(false);
                }).catch((error) => {
                    window.scrollTo(0, 50);
                    setProcessing(false);
                    setError(error);
                })

            } catch(error) {
                window.scrollTo(0, 50);
                setProcessing(false);
                setError(error);
            }
            
        }
    });

    async function verifyCaptcha() {
        const captchaToken = await recaptchaRef.current.executeAsync();

        recaptchaRef.current.reset();

        let verify = functions.httpsCallable('verifyCaptcha');

        const res = await verify({
            captchaToken: captchaToken
        });

        if(!res.data.success) throw new Error('Sorry, you have been caught by our anti-spam filters. Please try again.');

        return;
    }

    return (
        <Page>
            <PageTitle
                    title={"Create a Dugood account"}
            />
    
            <Container>
                    <Row>
                        <Col md={12}>
                        <h3>Hello and welcome to Dugood!</h3>
                        <p>Get started as a collector by creating your personal Dugood account. <br/>This account is linked to <b>you</b>, not an organisation. If you need to register an organisation, you can do that later.</p>

                        <div className={'aside mt-4 mb-5'}>
                            <p>If you're signing up on behalf of a charity, <a href={'/onboarding'}>click here</a></p>
                        </div>

                        <hr/>
                        </Col>
                    </Row> 

                    <form className="dg-form mt-4 mb-4" onSubmit={formik.handleSubmit}>
                        {error && 
                        <Row>
                            <Col>
                                <Alert variant={'danger'}>
                                    {error.message}
                                </Alert>
                            </Col>
                        </Row>
                        }  
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="fName">First name</FormLabel>
                                    <FormControl
                                        name={'fName'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fName}
                                        className={formik.errors.fName && 'invalid'}
                                        required
                                    />
                                    {formik.errors.fName && formik.touched.fName ? <div className={'validation'}>{formik.errors.fName}</div> : null}
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="lName">Last name</FormLabel>
                                    <FormControl
                                        name={'lName'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lName}
                                        className={formik.errors.lName && 'invalid'}
                                        required
                                    />
                                    {formik.errors.lName && formik.touched.lName ? <div className={'validation'}>{formik.errors.lName}</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="email">Email address</FormLabel>
                                    <FormControl
                                        name={'email'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        className={formik.errors.email && 'invalid'}
                                        required
                                    />
                                    {formik.touched.email && formik.errors.email ? <div className={'validation'}>{formik.errors.email}</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className={'mt-4 mb-4'}/>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="password">Password</FormLabel>
                                    <FormControl
                                        name={'password'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        className={formik.errors.password && 'invalid'}
                                        type={'password'}
                                        required
                                    />
                                    {formik.errors.password && formik.touched.password ? <div className={'validation'}>{formik.errors.password}</div> : null}
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="confirmPassword">Confirm password</FormLabel>
                                    <FormControl
                                        name={'confirmPassword'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmPassword}
                                        className={formik.errors.confirmPassword && 'invalid'}
                                        type={'password'}
                                        required
                                    />
                                    {formik.errors.confirmPassword && formik.touched.confirmPassword ? <div className={'validation'}>{formik.errors.confirmPassword}</div> : null}
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <h4 className="mt-1">Your password must contain:</h4>
                                <p>
                                    <ul>
                                        <li>at least 8 characters</li>
                                        <li>at least one capital letter</li>
                                        <li>at least one number</li>
                                        <li>at least one special character</li>
                                    </ul>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup className={'form-check'}>
                                    <FormControl
                                        name={'terms'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.terms}
                                        type={'checkbox'}
                                        className={'form-check-input'}
                                        required
                                    />
                                    <FormLabel htmlFor="terms" className={'form-check-label'}>I have read and agree to the Dugood Terms and Conditions and the Privacy Policy</FormLabel>
                                    {formik.errors.terms && formik.touched.terms ? <div className={'validation'}>{formik.errors.terms}</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={'mt-4'}>
                            <Col md={12}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={'6LfnWBEdAAAAAG1so4vU14XmeWMYSgzYvm4i5lht'}
                                    size={'invisible'}
                                />
                                {formik.errors.recaptcha && formik.touched.recaptcha ? <div className={'validation'}>{formik.errors.recaptcha}</div> : null}
                            </Col>
                        </Row>
                        <Row className={'mt-5 mb-4 d-flex align-items-centre'}>
                            <Col md={6} sm={12}>
                                <StartButton loading={processing} submit className={'mt-0'} title={"Create your account"}/>
                            </Col>
                            <Col md={6} sm={12} className={'d-flex justify-content-end align-items-center'}>
                                <a href={'/auth/login'} className={"pull-right"}>Return to login</a> 
                            </Col>
                        </Row>
                    </form>
                
            </Container>
            
        </Page>
    )
}