import React from 'react';
import './PageTitle.scss';

export default function PageTitle({subtitle, title, introText}) {
    return (
      <div className="dg-page-title">
          <img src={"/logo-c.svg"} alt={"Dugood"} className={"logo"}/>
          <div className={'text'}>
            <h3>{subtitle}</h3>
            <h1>{title}</h1>
            <p className="intro">{introText}</p>
          </div>
      </div>
    );
}