import React, {useEffect, useState} from 'react';
import { storage } from '../../../../firebase.js';
import './CampaignsListItem.scss';

const {Card, IconButton, Toast, Modal, Row, Col} = require('@dugood/dds')

const CampaignsListItem = (item, key, ...props) => {

    const [state, setState] = React.useState({
        data: item.item,
        imgUrl: undefined,
        toastVisible: false,
        modalVisible: false,
        imageDownloaded: false,
    });

    useEffect(() => {
        storage.ref().child(`qr/${item.item.organisation}/${item.item.id}.png`).getDownloadURL()
        .then(url => {
            setState({
                data: item.item,
                imgUrl: url
            })
        }).catch((error) => {
            console.log(error)
        });
    }, [item]);

    function copyUrl() {

        var url = '';

        if(state.data.amount) {
            url = `https://give.dugood.co.uk/?org=${state.data.organisation}&amount=${state.data.amount}&qr=${state.data.id}`;
        } else {
            url = `https://give.dugood.co.uk/?org=${state.data.organisation}&qr=${state.data.id}`;
        };

        navigator.clipboard.writeText(url);

        setState({
            ...state,
            toastVisible: true,
        });

        setTimeout(function(){
            setState({
                ...state,
                toastVisible: false,
            });
    

        }, 8000); 
    }
    

    async function downloadQR() {
        const filename = `dg-${state.data.friendly}.png`

        try {
            const response = await fetch(state.imgUrl);

            if (response.status !== 200) {
                throw new Error(`Unable to download file. HTTP status: ${response.status}`);
            }

            const blob = await response.blob();

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = filename;

            // Trigger the download
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Clean up
            setTimeout(() => {
                URL.revokeObjectURL(downloadLink.href);
                document.body.removeChild(downloadLink);
            }, 100);

            setState({
                ...state,
                imageDownloaded: true,
            })

        } catch(err) {
            console.error('Error downloading the file:', err.message);
        }
    }

    function printPoster() {
        window.open(`/secure/apps/poster?org=${state.data.organisation}&id=${state.data.id}`,'_blank')
    }

    function closeModal() {
        setState({
            ...state,
            modalVisible: false,
            imageDownloaded: false
        })
    }

    function openModal() {
        setState({
            ...state,
            modalVisible: true,
        })
    }

    return (
        <li key={key}>
            <Card className={'dg-card dg-list_item--campaign'} title={null}>
                <div className={'dg-list_item--campaign_img'}>
                    <img src={state.imgUrl} />
                </div>
                <div className={'dg-list_item--campaign_details'}>
                    <h4>{state.data.friendly}</h4>
                    <p><strong>£{state.data.tally}</strong> raised</p>
                </div>
                <div className={'dg-list_item--campaign_actions'}>
                    <div>
                        <IconButton icon={'QrCode'} 
                            onClick={() => {openModal()}} 
                            title={'See QR code'}
                        />
                        <p>Download</p>
                    </div>

                    <div>
                        <IconButton icon={'Link'} 
                            onClick={() => {copyUrl()}} 
                            title={'Share link'}
                        />
                        <p>Copy link</p>
                    </div>
                    
                    
                </div>
                
            </Card>
            <Toast
                type={'success'}
                visible={state.toastVisible}
                title={'Donation link copied'}
                body={'Share the link on social media or your website to allow people to donate to your campaign.'}
            />
            <Modal
                title={state.data.friendly}
                visible={state.modalVisible}
                handleClose={closeModal}
                id={state.data.id}
            >
                <div className={'dg-qr-modal'}>
                    <Row>
                        <Col lg={3} md={8} sm={8}>
                            <img src={state.imgUrl} className={'dg-qr-modal_img'}/>
                        </Col>
                        <Col lg={5} md={8} sm={8}>
                            <div className={'dg-qr-modal_details'}>
                                <div className={'dg-qr-modal_tally'}>
                                    <p><em>£{state.data.tally}</em> raised</p>
                                </div>
                                <div className={'dg-qr-modal_cards'}>
                                    <Card onClick={downloadQR}>
                                        <IconButton icon={state.imageDownloaded ? 'Check': 'Download'}/>
                                        {state.imageDownloaded ? 'Downloaded!' :'Download image'}
                                    </Card>
                                    <Card onClick={printPoster}>
                                        <IconButton icon={'Printer'}/>
                                        Print poster
                                    </Card>
                                </div>
                            </div>
                            
                        </Col>
                    </Row>
                </div>
            </Modal>
        </li>
    );
}

export default CampaignsListItem;