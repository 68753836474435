import React, {useState, useEffect} from 'react';
import { storage, db } from '../../../firebase.js';
import Loader from '../../../common/components/loader/Loader.js';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Canvas, Font, Image } from '@react-pdf/renderer';
import Satoshi from './Satoshi-Bold.ttf';
import './Poster.scss';

function Poster() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orgId = urlParams.get('org');
    const qr = urlParams.get('id');

    const [state, setState] = useState({
      orgName: null,
      img: null,
      givesafe: null,
    });

    useEffect(() => {

      async function fetchData() {
        let [orgInfo, url] = await Promise.all([getOrgInfo(orgId), getQR(orgId, qr)])

        console.log(url);

        setState({
          orgName: orgInfo.name,
          img: url,
          givesafe: orgInfo.gs
        });
      }
      
      try {
       fetchData();
      } catch(e) {

        alert(e);
      }
      
    }, []);
    

    return (
      <div className={'dg-poster'}>{(state.orgName != null && state.img != null)
        ?   <PDFViewer className={'dg-poster-viewer'}>
              <QrPoster orgName={state.orgName} img={state.img}/>
            </PDFViewer>
        :   <Loader/>}
      </div>
    );
}

async function getOrgInfo(orgId) {
  let orgData = await db.collection('organisations').doc(orgId).get();

  return {
    name: orgData.data().orgName,
    gs: orgData.data().givesafe,
  };
}

async function getQR(orgId, qr) {

  let url = await storage.ref().child(`qr/${orgId}/${qr}.png`).getDownloadURL();

  return url;

}

Font.register({ family: 'Satoshi', format: 'truetype', src: Satoshi});

const styles = StyleSheet.create({
  canvas: {
    width: '100%',
    height: '100%'
  },
  heading: {
    fontFamily: 'Satoshi',
    color: 'white',
    position: 'absolute',
    top: '50px',
    left: '50px',
    width: '500px',
    textAlign: 'center',
    margin: 'auto',
  },
  code: {
    position: 'absolute',
    top: '275px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '400px'
  },
  codeImg: {
    width: '400px',
    height: '400px',
    borderColor: 'white',
    borderWidth: '10px',
    borderStyle: 'solid',
    borderRadius: '10px',
  },
  instructions: {
    fontFamily: 'Satoshi',
    color: '#333',
    position: 'absolute',
    top: '700px',
    fontSize: '16px',
    textAlign: 'center',
    width: '100%'
  },
  logo: {
    position: 'absolute',
    height: '20px',
    width: '100%',
    bottom: '50px',
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  logoImg: {
    width: '72px'

  }
});

const QrPoster = ({orgName, img}) => (
  <Document>
    <Page size={'A4'}>
      <View>
        <Canvas 
          style={styles.canvas}
          paint={
            (painterObject) =>
            {
              painterObject.save()
              .circle(300,-400,800)
              .fill('#5e2bff')
            }
          }
        >
        </Canvas>
      </View>
      <View style={styles.heading}>
        <Text style={{fontSize: '30px', display: 'block'}}>
          Scan to give to
        </Text>
        <Text style={{fontSize: '55px', lineHeight: 1.1}} wrap={true}>
          {orgName}
        </Text>
        
      </View>
      <View style={styles.code}>
        <Image src={img} style={styles.codeImg}/>
      </View>
      <View style={styles.instructions}>
        <Text>
          Scan with your phone's camera to donate any amount
        </Text>
        <Text>
          with Apple Pay, Google Pay or card
        </Text>
      </View>
      <View style={styles.logo}>
        <Image src={"/dg-logo.png"} style={styles.logoImg}/>
      </View>
    </Page>
  </Document>
)

export default Poster;
