import React from 'react';
import StartButton from '../../common/components/startButton/StartButton';
import RegisterForm from '../../auth/forms/registerForm/RegisterForm';
import ChoiceButton from '../components/choiceButton/ChoiceButton'; 
import { FormControl, FormLabel, FormGroup, FormCheck } from 'react-bootstrap';
import { db } from '../../firebase.js';
import './Steps.scss';

import { useFormik } from "formik";

const {Input, Button, LargeButton, InfoBlock, Person} = require('@dugood/dds');

export function ErrorStep({body}) {
    return (
        <div className={'dg-step-visual'}>
            <div className={'illus-holder'}>
                <img src={'/illus/onboarding-error.svg'}/>
            </div>
            
            <p>{body}</p>
            
            
        </div>
        
    );
}

export function CreateAccount({successCallback}) {
    return (
        <div>
            <p>This is the login you'll use to access Dugood.</p>
            <RegisterForm successCallback={successCallback} />
        </div>
        
    );
}

export function AccountType({successCallback, failureCallback}) {

    

    return (
        <div>
            <LargeButton title={'Yes'}
                onClick={() => successCallback('registered_charity')}
            />
            <LargeButton title={'No'}
                onClick={() => successCallback('individual')}
            />

        </div>
        
    );
}

export function OrgDetails({type, successCallback, failureCallback}) {
    const validate = values => {
        const errors = {};
    
        if (!values.orgName) {
            errors.orgName = 'Required';
        } else if (values.orgName.length > 40) {
            errors.orgName = 'Max 40 characters';
        } else if (values.orgName.length < 5) {
            errors.orgName = 'At least 5 characters, please';
        }

        if (!values.orgNumber && type == 'registered_charity') {
            errors.orgNumber = 'Please provide your charity number.';
        } else if (!/^[0-9]*$/.test(values.orgNumber) && !/SC0[0-9]{5}/.test(values.orgNumber) && type == 'registered_charity') {
            errors.orgNumber = 'Please enter a valid charity number.';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            orgName: '',
            orgNumber: null
        },
        validate,
        onSubmit:  async (values, {resetForm, setSubmitting, setErrors}) => {

            if(type == 'registered_charity') {
                checkAlreadyExists(values.orgNumber).then(() => {
                    successCallback(values);
                }).catch((e) => {
                    failureCallback("An organisation with this charity number is already registered for Dugood. If this is the first time someone has registered your organisation, please contact us immediately.");
                })
            } else {
                successCallback(values);
            }

            
        }
    })

    return (
        <form>
                <Input
                    name={'orgName'}
                    value={formik.values.orgName}
                    onChange={formik.handleChange}
                    error={formik.errors.orgName}
                    label={'Name'}
                    hint={"Shows on your donation page and donors' bank statements"}
                    required
                />

                {type == 'registered_charity' &&
                    <Input
                    name={'orgNumber'}
                    value={formik.values.orgNumber}
                    onChange={formik.handleChange}
                    error={formik.errors.orgNumber}
                    label={'Charity number'}
                    required
                />
                }
                
                <Button
                    primary
                    label={'Continue'}
                    onClick={formik.handleSubmit}
                />
        </form>
        
    );
}

export function IsRegisteredCompany({yesCallback, noCallback, failureCallback}) {

    

    return (
        <div>
            <LargeButton title={'Yes'}
                onClick={yesCallback}
            />
            <LargeButton title={'No'}
                onClick={noCallback}
            />

        </div>
        
    );
}

export function CompanyNumber({successCallback, failureCallback}) {
    const validate = values => {
        const errors = {};

        if (!values.companyNumber) {
            errors.companyNumber = 'Please provide your company number.';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            companyNumber: null,
        },
        validate,
        onSubmit:  async (values, {resetForm, setSubmitting, setErrors}) => {
            successCallback(values.companyNumber)
        }
    })

    return (
        <form>
                <Input
                    name={'companyNumber'}
                    value={formik.values.companyNumber}
                    onChange={formik.handleChange}
                    error={formik.errors.companyNumber}
                    label={'Company number'}
                    required
                />
                
                <Button
                    primary
                    label={'Continue'}
                    onClick={formik.handleSubmit}
                />
        </form>
        
    );
}

export function DoesAnyoneOwnTwentyfivePercent({yesCallback, noCallback, failureCallback}) {

    

    return (
        <div>
            <LargeButton title={'Yes'}
                onClick={yesCallback}
            />
            <LargeButton title={'No'}
                onClick={noCallback}
            />

        </div>
        
    );
}

export function HasPersonsOfSigControl({yesCallback,noCallback}) {
    return (
        <div>

            <p>Significant control means:</p>

            <ul>
                <li>If you are a registered company, they hold over 25% of shares, or</li>
                <li>They hold more than 25% of voting rights, or</li>
                <li>They have significant influence over the charity <b>beyond what would be expected of their role.</b></li>
            </ul>

            <p>The final point means that, for example, trustees and chief executives are rarely people with significant control.</p>

            <InfoBlock type={'info'}>
                <p>You'll know if you have people with significant control. The law requires you to keep a register of your charity's PSCs.</p>
            </InfoBlock>

            <p>Click No if you're unsure. You can always change this later.</p>

            <LargeButton title={`Yes`}
                onClick={() => yesCallback()}
            />
            <LargeButton title={`No`}
                onClick={() => noCallback()}
            />
        </div>
        
    );
}

export function HasStripeAccount({type, yesCallback, noCallback}) {

    

    return (
        <div>
            <p>Dugood partners with Stripe to process donations. If you already take payments online, you might have a Stripe account. <a href={'https://stripe.com/gb'} target={'_blank'}>Learn more about Stripe</a></p>
            <LargeButton title={`${type == 'individual' ? 'I' : 'We'} have a Stripe account`}
                onClick={() => yesCallback()}
            />
            <LargeButton title={`${type == 'individual' ? 'I' : 'We'} do not have a Stripe account`}
                body={"Don't worry, we'll help you create one"}
                onClick={() => noCallback()}
            />
        </div>
        
    );
}

export function SigControlDetails({successCallback}) {

    const [state, setState] = React.useState({
        people: [],
    })

    function addNewPerson(value) {
        let newArr = state.people;
        newArr.push(value);
        setState({
            ...state,
            people: newArr,
        });
    }

    function updateRecord(value,i) {
        let newArr = state.people;
        newArr.splice(i,1,value);
        setState({
            ...state,
            people: newArr,
        });
    }

    function deletePerson(i) {
        let newArr = state.people;
        newArr.splice(i,1);
        setState({
            ...state,
            people: newArr,
        });
    }

    return (
        <div>
            <p>Please provide the details of your charity's people with significant control.</p>

            <InfoBlock type={'success'}>
                <p>This information helps us meet our regulatory requirements.</p>
            </InfoBlock>

            {state.people.map((person, i) => {return (
                    <Person 
                        value={person}
                        key={i}
                        onSubmit={(value,i) => updateRecord(value,i)}
                        deletable
                        onDelete={
                            (i) => {deletePerson(i)}
                        }
                        
                    />
            )})}

            <Button
                flex
                label={'Add a person'}
                onClick={() => addNewPerson({})}
            />

            <Button
                primary
                flex
                label={'Continue'}
                onClick={() => successCallback(state.people)}
            />

            
        </div>
        
    );
}

export function IndividualDetails({successCallback}) {

    const [state, setState] = React.useState({
        people: [{}],
    })

    function updateRecord(value,i) {
        let newArr = state.people;
        newArr.splice(i,1,value);
        successCallback(newArr);
    }

    return (
        <div>

            <InfoBlock type={'info'}>
                <p>It might seem overkill, but anti-money-laundering laws tell us to collect this info. Not that we're accusing you of anything!</p>
            </InfoBlock>

            {state.people.map((person, i) => {return (
                    <Person 
                        value={person}
                        key={i}
                        onSubmit={(value,i) => updateRecord(value,i)}
                        notRemovable
                        continueLabel={'Continue'}
                    />
            )})}
            
        </div>
        
    );
}

export function StripeHandoff({successCallback}) {

    const [loading, setLoading] = React.useState(false)

    return (
        <div>
            <p>Thank you! We've got everything we need.</p>
            <p>The last step is to create or log in to your Stripe account, and provide your bank details.</p>
            
            <InfoBlock type={'warning'}>
                <p>Leave your business type set to <b>individual</b>. This means you own the relationship with Dugood and makes signing up a lot faster. <a href='https://support.stripe.com/questions/selecting-a-business-type-and-business-structure?locale=en-GB' target='_blank'>Learn more</a></p>
            </InfoBlock>

            <p>Once you're done, we'll bring you right back here.</p>

            <Button
                primary
                flex
                label={'Get paid'}
                onClick={() => successCallback()}
            />
        </div>
        
    );
}



async function checkAlreadyExists(number) {

    try { 
        let querySnapshot = await db.collection("organisations").where("number", "==", number).get();

        if (!querySnapshot.empty) {
            return Promise.reject();
        } else {
            return Promise.resolve();
        }

    } catch(e) {
        console.log(e);
    }
}