import moment from 'moment';

export default function getBillingPeriods(data) {

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // Reduce runs a function on each element in an array
    // Split date into years
    const years = data.reduce((years, donation) => {

        const donationDate = moment.unix(donation.datetime.seconds).toDate();

        const donationYear = donationDate.getFullYear();

        if (!years[donationYear]) {
            years[donationYear] = [];
        }

        years[donationYear].push(donation);

        return years;
    }, {});

    // Split years into months

    Object.keys(years).forEach(function(year) { 
        years[year] = years[year].reduce((months, donation) => {

            const donationDate = moment.unix(donation.datetime.seconds).toDate();
    
            const donationMonth = donationDate.getMonth();
    
            if (!months[monthNames[donationMonth]]) {
                months[monthNames[donationMonth]] = [];
            }
    
            months[monthNames[donationMonth]].push(donation);
    
            return months;
        }, {})
    });

    return years;
};