import React from 'react';
import Page from '../../common/components/page/Page';
import Container from '../../common/components/container/Container';
import PageTitle from '../../common/components/pageTitle/PageTitle';

import {Row, Col} from 'react-bootstrap';

export default function CollectorComplete() {
    
    return (
        <Page>
            <PageTitle
                    title={"Start collecting for an organisation"}
            />
    
            <Container>
                <Row className={'mb-4'}>
                    <Col md={12}>
                        <img src={'/illus/illus-complete.svg'} className={'mb-4'} style={{width: '75px'}} alt={''}/>
                        <h3>You are now a collector!</h3>
                        <p>Welcome to Dugood! Your account has been successfully linked to your organisation and you can begin collecting for them.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className={'aside'}>
                            <h4>Collect on the go with the Scan to Give app</h4>
                            <p>As a collector, you can log in to the Scan to Give app to collect donations anywhere. You don't need any extra hardware - all you need is a phone running Android or an iPhone.</p>
                            <div className={'download-badges'}>
                                <img src={'/app-store.svg'} alt={'Download on the App Store'}/>
                                <img src={'/google-play.svg'} alt={'Download on Google Play'}/>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className={'mt-4'}>
                    <Col>
                        <small>
                        App Store and the App Store logo and badge are trademarks of Apple Inc. Google Play and the Google Play logo are trademarks of Google LLC.
                        </small>
                    </Col>
                </Row>
                
            </Container>
            
        </Page>
    )
}