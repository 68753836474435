import firebase, { functions, db } from '../../firebase.js';

export default async function createCollector(data) {
    
    var key = require('crypto').randomBytes(20).toString('hex');

    await addRecords(data, key);

    return Promise.resolve();
};

async function addRecords(data, key) {
    await db.collection("keys").add({
        email: data.email,
        key: key,
    });

    await db.collection("organisations").doc(data.orgId).update({
        keys: firebase.firestore.FieldValue.arrayUnion(key)
    });

    var sendInviteEmail = functions.httpsCallable('sendInviteEmail');
    
    await sendInviteEmail({
        email: data.email,
        orgName: data.orgName,
        key: key
    });

    return;
}