import React from 'react';
import Page from '../../common/components/page/Page';
import Container from '../../common/components/container/Container';
import PageTitle from '../../common/components/pageTitle/PageTitle';

import {Row, Col} from 'react-bootstrap';

export default function Complete() {
    
    return (
        <Page>
            <PageTitle
                    title={"Create a Dugood account"}
            />
    
            <Container>
                <Row className={'mb-4'}>
                    <Col md={12}>
                        <img src={'/illus/illus-complete.svg'} className={'mb-4'} style={{width: '75px'}} alt={''}/>
                        <h3>Your account has been created!</h3>
                        <p>Welcome to Dugood! Your account has been successfully created.
                        <br/>Check your email for a verification email to begin.
                        <br/>To start collecting, you have two options:
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <h4>Collect for an existing organisation</h4>
                        <p>If the organisation you want to collect for is already registered with Dugood, talk to their Dugood Admin and ask to be added as a collector.</p>
                    </Col>
                    <Col md={4} className={'offset-md-1'}>
                        <h4>Register a new organisation</h4>
                        <p>If you are authorised to do so and your organisation is not yet registered with Dugood, <a href={'/onboarding'}>click here</a> to register it.</p>
                    </Col>
                </Row>
                
            </Container>
            
        </Page>
    )
}