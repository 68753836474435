import { functions, db } from '../../../firebase.js';

const moment = require('moment');

export default async function prepareDashboard(data) {
    let org = await collectOrganisation(data.uid);

    if(org == null) {return null};

    // Collect QR codes - we need these for other pieces of data

    let qrCodes = await collectQRCodes(org);

    // Now get the rest of the data

    let returned = await Promise.all([collectDonations(org, data.uid, qrCodes), getStats(org)]);
    
    return Promise.resolve({
        org: org,
        donations: returned[0],
        qrCodes: qrCodes,
        stats: returned[1]
    });
};

async function collectOrganisation(uid) {
    var collectOrg = functions.httpsCallable('collectOrganisation');

    try {
        let org = await collectOrg({
            uid: uid
        });

        return org.data.result;
    } catch(e) {
        console.log(e)
    }
        
}

export async function collectQRCodes(org) {

    const orgId = org.orgId;
    let orgQRCodes = [];

    try { 
        let querySnapshot = await db.collection("qrcodes").where("organisation", "==", org.orgId)
        .get();

        querySnapshot.forEach((doc) => {
            let data = doc.data();
            orgQRCodes.push({
                type: data.type,
                created: data.created,
                organisation: data.organisation,
                tally: Number(data.tally).toFixed(2),
                id: data.id,
                friendly: data.friendly,
            });
        });

        return orgQRCodes;
    } catch(error) {
        console.log(error)
    }

};

export async function collectDonations(org, uid, qrCodes) {
    var orgDonations = [];

    try {
        let querySnapshot = await db.collection("donations").where("organisation", "==", org.orgId).get();

        querySnapshot.forEach((doc) => {
            orgDonations.push({
                id: doc.id,
                stripe: doc.data().stripe ? doc.data().stripe : null,
                amount: Number(doc.data().amount).toFixed(2),
                fee: doc.data().fee !== undefined ? doc.data().fee : 'Unknown',
                datetime: doc.data().datetime,
                giftaid: doc.data().giftaid,
                giftaid_address: doc.data().giftaid ? doc.data().giftaid_address : null,
                giftaid_postcode: doc.data().giftaid ? doc.data().giftaid_postcode : null,
                giftaid_name: doc.data().giftaid ? doc.data().giftaid_name : null,
                organisation: doc.data().organisation,
                qr: doc.data().qr,
                qrFriendly: doc.data().qr ? qrCodes.find(qr => {return qr.id === doc.data().qr}).friendly : null,
                source: doc.data().source,
            });

        });
    } catch(error) {
        console.log(error);
    };
    
    return orgDonations;

};

export async function getStats(org) {

    try {
        let doc = await db.collection("orgStats").doc(org.orgId).get();
        let data = doc.data();
        return {
            alltime: Number(data.alltime).toFixed(2),
            yeartodate: Number(data.yeartodate).toFixed(2),
            thisweek: Number(data.thisweek).toFixed(2),
            today: Number(data.today).toFixed(2)
        };
    } catch(error) {
        console.log(error);
    }

};