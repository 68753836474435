import React, {useState, useEffect} from 'react';
import './FlagsDisplay.scss';
import { db } from '../../../../firebase.js';
import {Carousel, CarouselItem} from 'react-bootstrap';

export default function FlagsDisplay(props) {

    const [state, setState] = useState({
        ready: false,
        flags: null
    });

    async function getFlagInfo(flags) {
        var result = [];

        if(flags == []) {
            return []
        }

        for (var i = 0; i < flags.length; i++) {
            try {
                let docRef = await db.collection("flags").doc(flags[i]).get();
                result.push(docRef.data());
            } catch {
                console.log('Could not find flag');
            };

            if(i === flags.length - 1) {
                return result.sort((a, b) => parseFloat(b.priority) - parseFloat(a.priority));;
            }
        }
    }

    // Get the info for the flags

    useEffect(() => {

        async function fetchData() {
            getFlagInfo(props.flags).then(flagInfo => {
                setState({
                    ready: true,
                    flags: flagInfo
                });
            })
        }

        fetchData();
        
        
    }, [props.flags]);

    return (
        <div className={`dg-flags`}>
        {state.ready ?
        <>
        {state.flags !== undefined ?
            <Carousel>
                {state.flags.map((flag, index) => {
                    return (<CarouselItem key={index} className={`dg-action-item ${flag.type}`} interval={5000} onClick={() => {window.location.href = flag.link}}>
                        <h4>
                            {flag.type === 'error'
                            && <span className="material-icons">report</span>}
                            {flag.type === 'warning'
                            && <span className="material-icons">warning</span>}
                            {flag.title}
                        </h4>
                        <p>{flag.message}</p>
                        <button onClick={() => {window.location.href = flag.link}}>
                            Fix this
                        </button>
                    </CarouselItem>)
                })}
            </Carousel>
        :
        <Carousel>
            <CarouselItem key={1} className={`dg-action-item announcement`} interval={5000} onClick={() => {window.location.href = 'https://www.dugood.co.uk/start'}}>
                <h4>
                    Welcome to Dugood!
                </h4>
                <p>Take a look at the Quick Start guide on our website to hit the ground running with your fundraising.</p>
                <button onClick={() => {window.location.href = 'https://www.dugood.co.uk/start'}}>
                    Show me
                </button>
            </CarouselItem>
        </Carousel>
        }
        </> :
        <>
            <div className={'dg-action-item loading'}>
                <img src={'loader-2.gif'} alt={'Loading'} className={'dg-loading'}/>
            </div>
        </>
        }
        </div>
    );
}