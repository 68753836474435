import React, {useContext, useEffect} from 'react';
import {UserContext} from '../../../providers/UserProvider';
import Card from '../components/card/Card';
import StartButton from '../../../common/components/startButton/StartButton';
import {Row, Col, FormGroup, FormControl, FormLabel, FormText, Alert, Modal} from 'react-bootstrap';
import { Formik } from "formik";


import { updateAccountDetails } from '../functions';

import { db } from '../../../firebase.js';
import { deleteUser } from '../../../auth/functions';

export default function AccSettings() {
    
    const user = useContext(UserContext).user;

    const [deleteModal, setDeleteModal] = React.useState(false);

    const [success, setSuccess] = React.useState(null);

    const [userData, setUserData] = React.useState(null);

    const [authError, setAuthError] = React.useState(null);
    
    const validate = values => {
        const errors = {};
    
        if (!values.fName) {
            errors.fName = 'Please provide your first name.';
        } else if (!values.lName) {
            errors.lName = 'Please provide your last name.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address.';
        }

        return errors;
    }

    const reauthValidate = values => {
        const errors = {};
    
        if (!values.email) {
            errors.email = 'Please provide your email address.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address.';
        }
    
        if (!values.password) {
            errors.password = 'Please provide your password.';
        }

        return errors;
    }

    //Get user data

    async function collectUserData() {

        var docRef = db.collection('users').doc(user.uid);
        const userData = await docRef.get();

        setUserData({
            fName: userData.data().fName,
            lName: userData.data().lName,
            email: userData.data().email
        });
    };

    useEffect(() => {
        collectUserData();
    }, []);

    return (
        <div>
            <Row>
                <Col md={12} id={'alert'}>
                    {success === true && <Alert variant={'success'}>
                        Settings updated successfully
                    </Alert>}

                    {success === false && <Alert variant={'error'}>
                        Something went wrong updating your settings. Please try again later.
                    </Alert>}
                </Col>
            </Row>
            {userData &&
            <Formik 
                initialValues={{
                    fName: userData.fName,
                    lName: userData.lName,
                    email: userData.email
                }}
                validate={validate}
                onSubmit={values => {
                    updateAccountDetails(user, userData, values).catch((error) => {
                        setSuccess(false);
                    }).then(() => {
                        setSuccess(true);
                    })
                }}
                className={'dg-form form'}
            >
            {props => (
            <form onSubmit={props.handleSubmit}>
            <p>These details are for your <b>personal</b> Dugood account, not for any organisations you manage or belong to.</p>
            
            <Row className={'mt-4'}>
                <Col md={6} sm={12}>
                    <Card title={"Your details"}>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel>First name</FormLabel>
                                    <FormControl
                                        onChange={props.handleChange}
                                        value={props.values.fName}
                                        className={props.errors.fName && 'invalid'}
                                        type={'text'}
                                        name={'fName'}
                                    />
                                    {props.errors.fName ? <div className={'validation'}>{props.errors.fName}</div> : null}
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel>Last name</FormLabel>
                                    <FormControl
                                        onChange={props.handleChange}
                                        value={props.values.lName}
                                        className={props.errors.lName && 'invalid'}
                                        type={'text'}
                                        name={'lName'}
                                    />
                                    {props.errors.lName ? <div className={'validation'}>{props.errors.lName}</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <FormLabel>Email address</FormLabel>
                            <FormControl
                                onChange={props.handleChange}
                                value={props.values.email}
                                className={props.errors.email && 'invalid'}
                                type={'email'}
                                name={'email'}
                                disabled
                            />
                            <FormText>Your email cannot currently be changed.</FormText>
                            {props.errors.email ? <div className={'validation'}>{props.errors.email}</div> : null}
                        </FormGroup>
                        
                        <input type={'submit'} value={'Save settings'}/>
                    </Card>
                    <Card title={"Delete account"}>
                        <p>If you would like to delete your personal Dugood account, you can do so here. You will be removed as a collector or an administrator from all organisations.</p>

                        <Alert variant={'danger'}>
                            <b>This action cannot be undone.</b>
                        </Alert>

                        <button onClick={() => setDeleteModal(true)}>Delete my account</button>
                    </Card>
                </Col>
            </Row>
            </form>
            )}
            </Formik>
            }

            <Modal show={deleteModal} onHide={() => {setDeleteModal(false)}} className={'dg-modal'}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete your account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'aside mb-4'}>
                        <p>Please re-enter your email address and password to confirm deletion of your account.</p>
                    </div>


                    {authError !== null  && 
                        <Alert variant={'danger'}>
                            {authError.message}
                        </Alert>
                    }
                    <Formik 
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validate={reauthValidate}
                        onSubmit={values => {
                            deleteUser(values.email, values.password).catch((error) => {
                                setAuthError(error);
                            })
                        }}
                        className={'dg-form form'}
                    >
                    {props => (<form onSubmit={props.handleSubmit}>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="email">Email address</FormLabel>
                                    <FormControl
                                        name={'email'}
                                        onChange={props.handleChange}
                                        value={props.values.email}
                                        className={props.errors.email && 'invalid'}
                                    />
                                    {props.errors.email ? <div className={'validation'}>{props.errors.email}</div> : null}
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="password">Password</FormLabel>
                                    <FormControl 
                                        type={"password"}
                                        name={'password'}
                                        onChange={props.handleChange}
                                        value={props.values.password}
                                        className={props.errors.password && 'invalid'}
                                    />
                                    {props.errors.password ? <div className={'validation'}>{props.errors.password}</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={'mt-2 mb-4 d-flex align-items-centre'}>
                            <Col md={6} sm={12}>
                                <StartButton submit className={'mt-0'} title={"Yes, delete my account"}/>
                            </Col>
                        </Row>
                    </form>)}
                    </Formik>

                    
                </Modal.Body>
            </Modal>
        </div>
    );
}