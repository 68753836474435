import React, {useContext, useEffect} from 'react';
import { OrgContext } from '../../Secure';
import Card from '../components/card/Card';
import Table from '../components/table/Table';
import Empty from '../components/empty/Empty';
import {Row, Col} from 'react-bootstrap';
import { CSVLink } from "react-csv";
import moment from 'moment';
const {Modal, InfoBlock, Button} = require('@dugood/dds');

export default function Donations() {
    const data = useContext(OrgContext).data;

    const orgId = useContext(OrgContext).currentOrg.orgId;

    const [giftAidDonations, setGiftAidDonations] = React.useState([]);

    const [gaModalOpen, setGaModal] = React.useState(false);

    function getSourceIcon(source) {
        switch(source) {
            case 'QR code':
                return <span title={'QR code'} className="material-icons">qr_code_scanner</span>
            case 'Scan to Give app':
                return <span title={'Scan to Give app'} className="material-icons">smartphone</span>
            default:
                return null;
        }
    }

    function getGiftAidIcon(giftaid, id) {
        if(giftaid) {return <a href={`/secure/apps/giftaiddec?id=${id}&org=${orgId}`} target={'_blank'}>Declaration</a>}
        else {return <span title={'No'} className="material-icons">close</span>}
    }

    let tableData = [];

    data.donations.map((item, index) => {
        tableData.push({
            amount: '£' + item.amount,
            date: moment.unix(item.datetime.seconds).format('DD MMM YYYY'),
            giftaid: getGiftAidIcon(item.giftaid, item.id),
            qr: item.qrFriendly,
            fee: item.fee !== 'Unknown' ? `£${item.fee}` : 'Unknown',
            sorter: item.datetime.seconds,
        });

        return;
    });

    useEffect(() => {

        async function fetchData() {
            let giftAidData = [];

            data.donations.map((item, index) => {
                if(item.giftaid === true) {
                    giftAidData.push({
                        name: item.giftaid_name,
                        address: item.giftaid_address,
                        postcode: item.giftaid_postcode,
                        aggregated_donations: null,
                        sponsored_event: null,
                        date: moment.unix(item.datetime.seconds).format('DD/MM/YY'),
                        amount: item.amount,
                        dugood_id: item.id,
                        stripe_id: item.stripe,
                    })
                };

                return;
                
            });

            setGiftAidDonations(giftAidData);
        }

        fetchData();
        
    }, []);

    return (
        <div>
            <Row>
                <Col md={12}>
                    <div className={'dg-quick-actions'}>
                        <CSVLink data={data.donations} filename={'donations.csv'} headers={
                            [{label: 'Amount', key: 'amount'},
                            {label: 'Date', key: 'datetime'},
                            {label: 'Giftaid?', key: 'giftaid'},
                            {label: 'QR Code ID (if applicable)', key: 'qr'},
                            {label: 'QR Code name (if applicable)', key: 'qrFriendly'},
                            {label: 'Source', key: 'source'},
                            {label: 'Dugood ID', key: 'dugood_id'}]
                        }>
                            <span>
                                <span className="material-icons">download</span>
                                Download CSV
                            </span>
                        </CSVLink>
                        <a onClick={() => setGaModal(true)}>
                            <span>
                            <span className="material-icons">account_balance</span>
                            Download Gift Aid report
                            </span>
                        </a>
                        
                    </div>
                </Col>
            </Row>
            <Card title={"All donations"}>
                {tableData.length !== 0
                ? <Table data={tableData} columns={['Amount', 'Date', 'Gift Aid','QR code', 'Fee', 'Sorter']} sortable={true}/>
                : <Empty icon={'payments'} title={'No donations to show yet'}/>
                }
            </Card>

            <Modal
                title={'Export Gift Aid reports'}
                visible={gaModalOpen}
                handleClose={() => setGaModal(false)}
            >
                <p>Download a record of Gift Aid declarations you can include in your schedule spreadsheet for HMRC. </p>

                <p>Your export will include all the information you need to submit to HMRC. Individual declarations can be downloaded on the Donations page.</p>

                <InfoBlock type={'info'}>
                Learn more about submitting online Gift Aid claims on <a href={"https://www.gov.uk/guidance/schedule-spreadsheet-to-claim-back-tax-on-gift-aid-donations#limit-per-spreadsheet"} target={"_blank"}>GOV.UK</a>.
                </InfoBlock>

                <CSVLink data={giftAidDonations} filename={'giftaid.csv'} headers={
                            [{label: 'Name', key: 'name'},
                            {label: 'House name or number', key: 'address'},
                            {label: 'Postcode', key: 'postcode'},
                            {label: 'Aggregated donations', key: 'aggregated_donations'},
                            {label: 'Sponsored event', key: 'sponsored_event'},
                            {label: 'Dugood ID', key: 'dugood_id'},
                            {label: 'Stripe ID', key: 'stripe_id'}]
                }>
                    <Button primary label={'Download spreadsheet'}/>
                </CSVLink>
            </Modal>
        </div>
    );
}