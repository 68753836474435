import React, {useContext} from 'react';
import UserProvider, {UserContext} from './providers/UserProvider';
import Onboarding from './onboarding/Onboarding';
import OnboardingComplete from './onboarding/views/Complete';
import Auth from './auth/Auth';
import NotFound from './common/views/NotFound/NotFound';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

import './App.css';
import './styles/variables.scss';
import Secure from './secure/Secure';

function AppRoute() {
    

    var authState = console.log(useContext(UserContext));

    return (
        <UserProvider>
        <Router>
            <Switch>
                <Route path="/onboarding/complete">
                    <OnboardingComplete/>
                </Route>
                <Route path="/onboarding">
                    <Onboarding />
                </Route>
                
                <Route path="/auth">
                    <Auth />
                </Route>
                <Route path="/secure">
                    <SecureRoute>
                        <Secure />
                    </SecureRoute>
                </Route>
                <Route path="/">
                    <SecureRoute>
                        <Redirect to={'/secure/dashboard'}></Redirect>
                    </SecureRoute>
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </Router>
        </UserProvider>
    );
}

function SecureRoute({children}) {
    let authState = useContext(UserContext);
    const params = new URLSearchParams(window.location.search).toString();

    /*<>{(authState.ready && !authState.user) ?
        <Redirect to={`/auth/login${params ? `?${params}` : ''}`}/>
    : (authState.user && !authState.user.emailVerified) ?
        <Redirect to={'/auth/verify'}/>
    :
        <>{children}</>
    }
    </>*/

    return (
            <>{children}</>
    )
    
}

export default AppRoute;
