import React, {useState, useEffect, useContext} from 'react';
import { OrgContext } from '../../Secure';
import Loader from '../../../common/components/loader/Loader';
import moment from 'moment';
import Decimal from 'decimal.js';
import './Invoice.scss';

function Invoice() {
    const data = useContext(OrgContext);

    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"
    ];

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const period = urlParams.get('period');

    let month, year = undefined;

    if(period.length === 5) {
        month = period.slice(0,1);
        year = period.slice(1)
    } else {
        month = period.slice(0,2);
        year = period.slice(2)
    }

    const [state, setState] = useState({
      data: undefined
    });

    useEffect(() => {
      async function fetchData() {
        try {
          let numbers = await filterData(month, year, data.data.donations);

          setState({
            data: numbers,
            totalFee: Number(numbers.dgFee) + Number(numbers.stripeFee),
          });

        } catch(e) {

          alert(e);
        }
      }

      fetchData();
      
      
    }, []);

    

    return (
      <>{(state.data !== undefined)
        ?   <div className={'dg-invoice'}>

              <div className={'no-print'}>
                <span className="material-icons" onClick={() => window.print()}>
                  print
                </span>
              </div>
              
              <div className={'header'}>
                  <div className={'col-logo'}>
                    <img src={'/logo-c.svg'} alt={''}/>
                  </div>
                  <div className={'col-address'}>
                    <p>
                      Dugood Ltd <br/>
                      Unit 8 <br />
                      Blue Barns Business Park <br/>
                      Old Ipswich Road <br/>
                      Colchester <br/>
                      CO7 7FX
                    </p>
                  </div>
              </div>

              <div className={'intro'}>
                <h2>Statement of Fees ({monthNames[month]} {year})</h2>
                <h3>For <span className={'orgName'}>{data.data.org.orgName}</span> <br/>
                Charity number <span className={'orgNumber'}>{data.data.org.number}</span>
                </h3>
              </div>

              <div className={'totals'}>
                <div className={'total'}>
                  <h2>£{state.data.totalDonations}</h2>
                  <p>Total collected for this period</p>
                </div>

                <div className={'total'}>
                  <h2>£{state.totalFee}</h2>
                  <p>Total fees paid this period</p>
                </div>
              </div>

              <div className={'breakdown'}>
                <h3>Breakdown of fees</h3>

                <div className={'breakdown-item'}>
                  <div className={'details'}>
                    <h4>Dugood fee</h4>
                    <p className={'percentage'}>1.6% fee on each donation</p>
                    <p>This is charged for the use of Dugood services, including Scan to Give.</p>
                  </div>
                  <div className={'total'}>
                    <h4>£{state.data.dgFee}</h4>
                  </div>
                </div>

                <div className={'breakdown-item'}>
                  <div className={'details'}>
                    <h4>Stripe fee</h4>
                    <p className={'percentage'}>1.4% + 20p fee on each donation</p>
                    <p>This is charged by our payment processor to cover the cost of processing each donation. Dugood neither controls nor receives this fee.</p>
                  </div>
                  <div className={'total'}>
                    <h4>£{state.data.stripeFee}</h4>
                  </div>
                </div>
              </div>
            
        </div>
        :   <Loader/>}
      </>
    );
}

async function filterData(month, year, data) {

  let toReturn = {
    totalDonations: 0,
    stripeFee: new Decimal(0),
    dgFee: new Decimal(0),
  }

 
  data.map((donation, i) => {
    const donationDate = moment.unix(donation.datetime.seconds).toDate();

    if(donationDate.getFullYear() == year && donationDate.getMonth() == month) {
      toReturn = {
        totalDonations: toReturn.totalDonations + donation.amount,
        stripeFee: toReturn.stripeFee.plus(Decimal(((donation.amount * 0.014) + 0.2)).toDecimalPlaces(2)),
        dgFee: toReturn.dgFee.plus(Decimal((donation.amount * 0.016)).toDecimalPlaces(2))
      }
    }
    return;
  });

  return {
    totalDonations: toReturn.totalDonations,
    stripeFee: Number(toReturn.stripeFee),
    dgFee: Number(toReturn.dgFee)
  };
}

export default Invoice;
