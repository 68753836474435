import React, {useContext} from 'react';
import {UserContext} from '../../providers/UserProvider';
import { auth } from '../../firebase.js';
import Page from '../../common/components/page/Page';
import Container from '../../common/components/container/Container';
import PageTitle from '../../common/components/pageTitle/PageTitle';
import StartButton from '../../common/components/startButton/StartButton';
import queryString from 'query-string';
import { collectorAcceptInvite } from '../functions';

import {Row, Col} from 'react-bootstrap';

export default function Confirm() {

    const authReady = useContext(UserContext).ready;

    const linkParams = queryString.parse(window.location.search);

    if(!linkParams.key) {
        window.location.href = '/auth/login'
    }

    if(authReady && !auth.currentUser) {
        window.location.href = `/auth/login?callback=confirm&key=${linkParams.key}`
    }
    
    return (
        <Page>
            <PageTitle
                    title={"Start collecting for an organisation"}
            />

            <Container>
                <Row className={'mb-4'}>
                    <Col md={12}>
                        <img src={'/illus/illus-invite.svg'} className={'mb-4'} style={{width: '75px'}} alt={''}/>
                        <h3>You have been invited to collect for an organisation</h3>
                        <p>Someone at an organisation has invited you to become a collector for their organisation.
                        </p>
                        <p>For security reasons, we can't tell you which organisation has invited you. If you weren't expecting this invite, you can discard it.
                        </p>
                        <p>If you'd like to, you can accept the invitation.</p>
                        <StartButton submit className={'mt-0'} title={"Accept invitation"} onClick={() => collectorAcceptInvite({key: linkParams.key}).catch((error) => {alert(`Sorry, this invitation could not be accepted. Error: ${error}`)})}/>
                    </Col>
                </Row>
                
            </Container>
            
        </Page>
    )
}