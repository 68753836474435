import { auth } from '../../firebase.js';
import queryString from 'query-string';

export default async function login(data) {
    const linkParams = queryString.parse(window.location.search);

    await auth.signInWithEmailAndPassword(data.email, data.password);

    linkParams.callback
    ?  
        linkParams.callback === 'onboarding'
        ? window.location.href =`/${linkParams.callback}?step=1`
        : linkParams.key
            ? window.location.href =`/auth/${linkParams.callback}?key=${linkParams.key}`
            : window.location.href =`/${linkParams.callback}`
    
    :  window.location.href ='/secure/dashboard'; 
        
}