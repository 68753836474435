import React, { createContext } from 'react';
import { auth } from '../firebase.js';

export const UserContext = createContext({ user: null });
class UserProvider extends React.Component {
  state = {
    user: auth.currentUser,
    ready: false,
  };

  componentDidMount = () => {
    auth.onAuthStateChanged(userAuth => {
      this.setState({ user: userAuth, ready: true });
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
