import React, {useState} from 'react';
import Page from '../../common/components/page/Page';
import Container from '../../common/components/container/Container';
import PageTitle from '../../common/components/pageTitle/PageTitle';
import StartButton from '../../common/components/startButton/StartButton';

import {reset} from '../functions';
import { useFormik } from "formik";

import {Form, FormControl, FormGroup, FormLabel, Row, Col, Alert} from 'react-bootstrap';

export default function Reset() {

    const [error, setError] = useState({
        code: undefined,
        message: undefined
    });

    const [sent, setSent] = useState(false)

    const validate = values => {
        const errors = {};
    
        if (!values.email) {
            errors.email = 'Please provide your email address.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address.';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate,
        onSubmit: values => {
            reset(values).then(() => {
                setTimeout(resetForm, 180000)
            }).catch(error => setError(error))
        }
    })

    function resetForm() {
        setSent(false)
    }
    
    return (
        <Page>
            <PageTitle
                    title={"Reset your password"}
            />
    
            <Container>
                {error.code !== undefined  && 
                    <Row>
                        <Col>
                            <Alert variant={'danger'}>
                                {error.message}
                            </Alert>
                        </Col>
                    </Row>
                }
                {sent  && 
                    <Row>
                        <Col>
                            <Alert variant={'success'}>
                                Check your inbox for your reset link. If it hasn't arrived, you can request a new one in 3 minutes.
                            </Alert>
                        </Col>
                    </Row>
                }
                    <Form className="dg-form" onSubmit={formik.handleSubmit}>
                        <p>If you have a Dugood account and have forgotten your password, enter your email address to receive a password reset link.</p>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="email">Email address</FormLabel>
                                    <FormControl
                                        name={'email'}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        className={formik.errors.email && 'invalid'}
                                    />
                                    {formik.errors.email ? <div className={'validation'}>{formik.errors.email}</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={'mt-2 mb-4 d-flex align-items-centre'}>
                            <Col md={6} sm={12}>
                                <StartButton disabled={sent} submit className={'mt-0'} title={"Send reset link"}/>
                            </Col>
                            <Col md={6} sm={12} className={'d-flex justify-content-end align-items-center'}>
                                <a href={'/auth/login'} className={"pull-right"}>Return to login</a> 
                            </Col>
                        </Row>
                    </Form>
                
            </Container>
            
        </Page>
    )
}