import { db, auth } from '../../../firebase.js';

export default async function updateAccountDetails(user, currentData, newData) {

    if(user !== auth.currentUser) {
        throw new Error("Something went wrong and reauthentication is required. Please log out and log in again.");
    };

    db.collection("users").doc(user.uid).update(newData).then(() => {
        return Promise.resolve();
    });
};