import React, {useState, useEffect} from 'react';
import { storage, db } from '../../../firebase.js';
import Loader from '../../../common/components/loader/Loader.js';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Canvas, Font, Image } from '@react-pdf/renderer';
import moment from 'moment';
import './GiftAidDec.scss';

function GiftAidDec() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    const orgId = urlParams.get('org');

    const [data, setData] = useState(null);

    useEffect(() => {

      async function fetchData() {
        let [donation, orgName] = await Promise.all([getDonationInfo(id), getOrgInfo(orgId)])

        setData({
          id: id,
          donation: donation,
          orgName: orgName
        });
      }
      
      try {
       fetchData();
      } catch(e) {

        alert(e);
      }
      
    }, []);
    

    return (
      <div className={'dg-giftAidDec'}>{(data != null)
        ?   <PDFViewer className={'dg-giftAidDec-viewer'}>
              <Dec data={data}/>
            </PDFViewer>
        :   <Loader/>}
      </div>
    );
}

async function getDonationInfo(id) {
  const docRef = db.collection('donations').doc(id);

  const doc = await docRef.get();

  if(doc.exists) {
    return {
      name: doc.data().giftaid_name,
      address: doc.data().giftaid_address,
      postcode: doc.data().giftaid_postcode,
      amount: doc.data().amount,
      datetime: doc.data().datetime,
    }
  } else {
    return null
  }
}

async function getOrgInfo(orgId) {
  let orgData = await db.collection('organisations').doc(orgId).get();

  return orgData.data().orgName;
}

const styles = StyleSheet.create({
  page: {
    padding: '50',
    fontFamily: "Helvetica",
  },
  heading: {
    textAlign: 'center',
    margin: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    width: 200,
    fontFamily: "Helvetica-Bold",
    fontSize: '12',
  },
  value: {
    fontSize: '12',
  },
  id: {
    width: 500,
    fontSize: 10,
  },
  declaration: {
    marginTop: 30,
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    width: 400,
  },
  tick: {
    width: 15,
    height: 15,
    borderWidth: 1,
    textAlign: 'center',
    marginRight: 25,
  }
});

const Dec = ({data}) => (
  <Document>
    <Page size={'A4'} style={styles.page}>
      <View style={styles.heading}>
        <Text style={{fontSize: '30px', display: 'block'}}>
          Gift Aid declaration
        </Text>
        <Text style={{fontSize: '14px', display: 'block'}}>
          Yes! I want to Gift Aid my donation of £{data.donation.amount} to {data.orgName}
        </Text>
      </View>
      <View style={styles.declaration}>
        <Text style={styles.value}>
          About your donation
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>
          Amount
        </Text>
        <Text style={styles.value}>
          £{data.donation.amount}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>
          Charity name
        </Text>
        <Text style={styles.value}>
          {data.orgName}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>
          Date
        </Text>
        <Text style={styles.value}>
          {moment.unix(data.donation.datetime.seconds).format('DD/MM/YY')}
        </Text>
      </View>
      <View style={styles.declaration}>
        <Text style={styles.value}>
          About you
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>
          Name
        </Text>
        <Text style={styles.value}>
          {data.donation.name}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>House name/number</Text>
        <Text style={styles.value}>
          {data.donation.address}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>
          Postcode
        </Text>
        <Text style={styles.value}>
          {data.donation.postcode}
        </Text>
      </View>
      <View style={styles.declaration}>
        <Text style={styles.tick}>
          Y
        </Text>
        <Text style={styles.value}>
        I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on all my donations it is my responsibility to pay any difference.
        </Text>
      </View>
      <View style={styles.declaration}>
        <Text style={styles.value}>
        If you pay Income Tax at the higher or additional rate and want to receive the additional tax relief due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask HM Revenue and Customs to adjust your tax code.
        </Text>
      </View>
      <View style={styles.declaration}>
        <Text style={styles.value}>
          Notify the charity if you want to cancel this declaration, change your name or address, or no longer pay sufficient tax on your income and/or capital gains.
        </Text>
      </View>
      <View style={styles.declaration}>
        <Text style={styles.value}>
          This declaration was electronically submitted as part of a Dugood donation.
        </Text>
      </View>
      <View style={styles.declaration}>
        <Text style={styles.value}>
          Donation ID for record-keeping purposes
        </Text>
      </View>
      <Text style={styles.id}>
          {data.id}
        </Text>
      
    </Page>
  </Document>
)

export default GiftAidDec;
