import React, {useState} from 'react';
import './MobileTableItem.scss';

export default function MobileTableItem(row, className) {

    let [expanded, setExpanded] = useState(false);

    let dataArray = row.row;
    const dataCopy = [...dataArray];

    let headline = row.row[0];

    dataCopy.shift();

    return (
        <li className={`dg-mobile-table-item`}>
            <div className={'headline'}>
                <p>{headline.value}</p>
                <p className={'material-icons'} onClick={() => {setExpanded(!expanded)}}>
                    {expanded ? 'expand_less' : 'expand_more'}
                </p>
            </div>
            <div className={`data ${expanded}`}>
                {dataCopy.map((item, i) => {
                    return(
                        <div className={'item'}>
                            <label>{item.column.Header}</label>
                            <p>{item.value}</p>
                        </div>
                    )
                })}
            </div>
        </li>
    );
}