import { db, storage } from '../../../firebase.js';

export default async function createQRCode(data) {

    var QRCode = require('qrcode');

    let url = '';

    if(data.amount) {
        url = `https://give.dugood.co.uk/?org=${data.orgId}&amount=${data.amount}&ga=${data.giftaid == true ? 'true' : 'false'}&qr=${data.id}`;
    } else {
        url = `https://give.dugood.co.uk/?org=${data.orgId}&ga=${data.giftaid == true ? 'true' : 'false'}&qr=${data.id}`;
    }

    // Make the canvas and set it up

    var canvas = document.createElement('canvas');

    canvas.width = 600;
    canvas.height = 600;

    // Get the drawing context
    var context = canvas.getContext('2d');

    // Make the QR Code

    QRCode.toCanvas(canvas, url, {
        margin: 8,
        color: {
        dark: '#FF8F1C'
    }, width: 600, height: 600}, );

    // Add an orange border

    context.lineWidth = 100;
    context.strokeStyle="#FF8F1C";

    context.beginPath();
    context.rect(0, 0, 600, 600);
    context.stroke();

    var storageRef = storage.ref();
    var imageRef = storageRef.child(`qr/${data.orgId}/${data.id}.png`);

    let snapshot = await imageRef.putString(canvas.toDataURL(), 'data_url');

    await db.collection('qrcodes').doc(data.id).set({
        friendly: data.friendly,
        id: data.id,
        tally: 0,
        organisation: data.orgId,
        type: '001',
        created: data.created,
    });

    return true;

}