import React, {useState} from 'react';
import queryString from 'query-string';
import Page from '../../common/components/page/Page';
import Container from '../../common/components/container/Container';
import PageTitle from '../../common/components/pageTitle/PageTitle';
import StartButton from '../../common/components/startButton/StartButton';

import {login} from '../functions';
import { useFormik } from "formik";

import {Form, FormControl, FormGroup, FormLabel, Row, Col, Alert} from 'react-bootstrap';

export default function Login() {
    const linkParams = queryString.parse(window.location.search);

    const [error, setError] = useState(null);

    const validate = values => {
        const errors = {};
    
        if (!values.email) {
            errors.email = 'Please provide your email address.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address.';
        }
    
        if (!values.password) {
            errors.password = 'Please provide your password.';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate,
        onSubmit: async values => {
            login(values).catch((error) => {setError(error)})
        }
    })
    
    return (
        <Page>
            <PageTitle
                    title={"Log in to your account"}
                    subtitle={"Log in for individuals"}
            />
    
            <Container>
                {error !== null  && 
                    <Row>
                        <Col>
                            <Alert variant={'danger'}>
                                {error.message}
                            </Alert>
                        </Col>
                    </Row>
                }
                    <Form className="dg-form" onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="email">Email address</FormLabel>
                                    <FormControl
                                        name={'email'}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        className={formik.errors.email && 'invalid'}
                                    />
                                    {formik.errors.email ? <div className={'validation'}>{formik.errors.email}</div> : null}
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <FormLabel htmlFor="password">Password</FormLabel>
                                    <FormControl 
                                        type={"password"}
                                        name={'password'}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        className={formik.errors.password && 'invalid'}
                                    />
                                    {formik.errors.password ? <div className={'validation'}>{formik.errors.password}</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={'mt-2 mb-4 d-flex align-items-centre'}>
                            <Col md={6} sm={12}>
                                <StartButton submit className={'mt-0'} title={"Sign in"}/>
                            </Col>
                            <Col md={6} sm={12} className={'d-flex justify-content-end align-items-center'}>
                                <a href={'/auth/reset'} className={"pull-right"}>Forgotten password?</a> 
                            </Col>
                        </Row>
                    </Form>

                <Row>
                    <Col>
                        <div className={'aside mt-4'}>
                            <p>Don't have a Dugood account yet? <a href={`/onboarding${linkParams.callback ? `?callback=${linkParams.callback}` : ''}${linkParams.key ? `&key=${linkParams.key}` : ''}`}>Create one</a></p>
                        </div>
                    </Col>
                </Row>
                
            </Container>
            
        </Page>
    )
}