import React from 'react';
import './Footer.scss';
import {Row, Col} from 'react-bootstrap';

export default function Footer() {
    return (
      <div className="dg-footer">
                <img src={"/slogan.svg"} className={'slogan'} alt={"Release What's There"}/>
                <img src={"/small-logo-alt.svg"} className={'du'} alt={'Du logo'}/>
      </div>
    );
}