import React, {useState, useEffect, useContext} from 'react';
import { UserContext } from '../providers/UserProvider';
import Page from '../common/components/page/Page';
import Container from '../common/components/container/Container';
import PageTitle from '../common/components/pageTitle/PageTitle';
import StartButton from '../common/components/startButton/StartButton';
import Tracker from './components/tracker/Tracker';
import Card from '../secure/dashboard/components/card/Card';
import * as Steps from './views/Steps';

import { functions, auth, db } from '../firebase.js';
import queryString from 'query-string';
import { TabContainer, TabContent, TabPane, Tab } from 'react-bootstrap';
import './Onboarding.scss';
const {Row,Col,FlowScreen,Progress,Loader} = require('@dugood/dds');

export default function Onboarding() {

    /* Constants and variables */

    const user = useContext(UserContext).user;
    const linkParams = queryString.parse(window.location.search);

    /* States */

    const [state, setState] = useState({
        step: 'CreateAccount',
        response: {orgName: undefined,
        orgType: 'individual',
        orgNumber: undefined,
        orgContact: {
            fName: undefined,
            lName: undefined,
            email: undefined
        },
        marketing: undefined},
        error: null,
        loading: true,
        promo: null,
        connectingToStripe: false,
    })

    useEffect(() => {
        prepareData();
    }, [user]);

    // Available params: state, promo

    const steps = [
        {
            key: 0,
            title: 'Something went wrong',
        },
        {
            key: 'CreateAccount',
            title: 'Create your account'
        },
        {
            key: 'OrgDetails',
            title: state.response.orgType == 'registered_charity' ? 'About your charity' : 'What is your group or cause called?'
        },
        {
            key: 'HasStripeAccount',
            title: 'Are you registered with Stripe?'
        },
        {
            key: 'IndividualDetails',
            title: 'About you'
        },
        {
            key: 'StripeHandoff',
            title: 'Connect to your bank account'
        },
        {
            key: 'complete',
            title: 'Good to go!'
        },
    ];

    const noSteps = steps.length;

    

    async function prepareData() {
        try {
            var promo = null;
            var startingStep = 'CreateAccount';

            // First, check if logged in

            user && await checkCanContinue(); // Will go to step 0 with an error if not allowed, else will continue

            // Secondly check for any promo codes

            if(linkParams.promo) {
                let promoData = await checkValidPromo(linkParams.promo);
                if(promoData != null) {
                    promo = promoData;
                }
            }

            // Check if there is a step param

            if(linkParams.step) {
                startingStep = linkParams.step;
            }

            

            // Ensure that if there is no step param, logged in users go to step 2

            if(user && startingStep == 'CreateAccount') {startingStep = 'OrgDetails'}

            // Now set the state

            setState({
                ...state,
                step: startingStep,
                promo: promo,
                loading: false
            });
        } catch(err) {
            setState({
                ...state,
                step: 0,
                error: err,
            })
        }
    }

    async function checkValidPromo(code) {
        let doc = await db.collection("promocodes").doc(code).get();


        if(doc.exists) {
            let today = new Date();
            let expiry = doc.data().expires.toDate();

            if(expiry > today) {
                return doc.data();
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    async function createAccount() {
        setState({
            ...state,
            connectingToStripe: true,
        })


        var createAccount = functions.httpsCallable('onboardCreateAccount');
        createAccount({
            response: state.response,
            uid: user.uid,
            promo: state.promo,
        }).then((result) => {
            window.location.href = result.data.link;
        }).catch((err) => console.log(err));
    }

    function throwError(error) {
        setState({
            ...state,
            step: 0,
            error: error
        })
    }

    /* Check the user doesn't already have an organisation */

    async function checkCanContinue() {
        let uid = auth.currentUser.uid;

        let result = await db.collection('organisations').where('admins', 'array-contains', `/users/${uid}`).get();

        if(!result.empty) {
            setState({
                ...state,
                step: 0,
                error: "You can't register a new organisation because you're already an admin or collector for another organisation. To add yours, ask an admin at your current organisation to remove you, or create a new Dugood account."
            })
        } else {
            setState({
                ...state,
                step: 'CreateAccount'
            });
        }
    }

    function getStepName(key) {
        let step = steps.find(o => o.key === key);
        return step.title;
    }

    return (
    <div className={'dds'}>
        {state.loading 
        ? <Loader 
            title={'Getting ready...'}
            visible={true}
        />
        :
        <FlowScreen
            title={`Start fundraising for free ${state.promo ? `with £${state.promo.entitlement} free credit` : ''}`}
            leftContent={<Progress totalSteps={steps.length} currentStep={steps.indexOf(steps.find(o => o.key === state.step))}/>}
            logoUrl={'/logo-c.svg'}
        >
            <Loader
                title={'This might take a minute, please wait...'}
                visible={state.connectingToStripe}
            />

            <p className={'margin-btm'}>You’re just a few steps away from collecting cashless donations.</p>
            
            
            <Tab.Container activeKey={state.step}>
                <Tab.Content className={'dg-tabs'}>
                <h4>{getStepName(state.step)}</h4>

                    <Tab.Pane eventKey={'CreateAccount'}>
                        <Steps.CreateAccount successCallback={(values) => setState({...state, 
                            step: 'OrgDetails',
                            response: {
                                ...state.response,
                                orgContact: {
                                    fName: values.fName,
                                    lName: values.lName,
                                    email: values.email
                                }
                            }
                        })}/>
                    </Tab.Pane>

                    

                    <Tab.Pane eventKey={'OrgDetails'}>
                        <Steps.OrgDetails 
                            type={state.response.orgType} 

                            successCallback={(values) => setState({...state, 
                                step: 'HasStripeAccount',
                                response: {
                                    ...state.response,
                                    orgName: values.orgName,
                                    orgNumber: values.orgNumber
                                }
                            })}

                            failureCallback={(error) => throwError(error)}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'HasStripeAccount'}>
                        <Steps.HasStripeAccount 

                            type={state.response.orgType}

                            yesCallback={() => {
                                setState({...state,
                                    step: 'StripeHandoff'
                                })
                            }}

                            noCallback={() => {
                                setState({...state,
                                    step: 'IndividualDetails'
                                })
                            }}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'IsRegisteredCompany'}>
                        <Steps.IsRegisteredCompany 
                            yesCallback={() => {
                                setState({...state,
                                    step: 'CompanyNumber',
                                    orgType: 'registered_company'
                                })
                            }}

                            noCallback={() => {
                                setState({...state,
                                    step: 'HasPersonsOfSigControl'
                                })
                            }}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'CompanyNumber'}>
                        <Steps.CompanyNumber 
                            successCallback={(values) => setState({...state, 
                                step: 'DoesAnyoneOwnTwentyfivePercent',
                                response: {
                                    ...state.response,
                                    orgNumber: values
                                }
                            })}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'DoesAnyoneOwnTwentyfivePercent'}>
                        <Steps.DoesAnyoneOwnTwentyfivePercent 
                            yesCallback={() => {
                                setState({...state,
                                    step: 'SigControlDetails'
                                })
                            }}

                            noCallback={() => {
                                setState({...state,
                                    step: 'HasPersonsOfSigControl'
                                })
                            }}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'HasPersonsOfSigControl'}>
                        <Steps.HasPersonsOfSigControl
                            yesCallback={() => {
                                setState({...state,
                                    step: 'SigControlDetails'
                                })
                            }}

                            noCallback={() => {
                                setState({...state,
                                    step: 'StripeHandoff'
                                })
                            }}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'SigControlDetails'}>
                        <Steps.SigControlDetails  
                            successCallback={(values) => setState({...state, 
                                step: 'StripeHandoff',
                                response: {
                                    ...state.response,
                                    sigControlDetails: values
                                }
                            })}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'IndividualDetails'}>
                        <Steps.IndividualDetails  
                            successCallback={(values) => setState({...state, 
                                step: 'StripeHandoff',
                                response: {
                                    ...state.response,
                                    sigControlDetails: values
                                }
                            })}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={'StripeHandoff'}>
                        <Steps.StripeHandoff 
                            successCallback={
                                () => createAccount()
                            }
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey={0}>
                        <Steps.ErrorStep body={state.error} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </FlowScreen>
        

        }
        
    </div>
    );
}