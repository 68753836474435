import React, {useState, useContext} from 'react';
import Home from './views/Home';
import Donations from './views/Donations';
import ScanToGive from './views/ScanToGive';
import OrgSettings from './views/OrgSettings';
import AccSettings from './views/AccSettings';
import TabTitle from './components/tabTitle/TabTitle';
import Page from '../../common/components/page/Page';
import { TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { OrgContext } from '../Secure';
import './Dashboard.scss';
import { auth } from '../../firebase';
const dds = require('@dugood/dds');
const {Nav, Row, Col} = require('@dugood/dds')


export default function Dashboard() {

    const currentOrg = useContext(OrgContext).currentOrg;

    const [state, setState] = useState({
        currentTab: 'Home',
    });

    const tabs = [
        {
            key: 'Home',
            name: 'Home',
            icon: 'Home',
            onClick: () => {changeTab('Home')}
        },
        {
            key: 'Donations',
            name: 'Donations',
            icon: 'Coins',
            onClick: () => {changeTab('Donations')}
        },
        {
            key: 'Campaigns',
            name: 'Campaigns',
            icon: 'Megaphone',
            onClick: () => {changeTab('Campaigns')}
        },
        {
            key: 'Organisation Settings',
            name: 'Organisation',
            icon: 'Landmark',
            onClick: () => {changeTab('Organisation Settings')}
        },
        {
            key: 'Account Settings',
            name: 'Account',
            icon: 'User',
            onClick: () => {changeTab('Account Settings')}
        }
    ]

    function changeTab(tab) {
        setState({
            ...state,
            currentTab: tab
        })
    }

    return (
        <div className={'dds dg-dashboard'}>
            <Row>
                <Col lg={2} md={0} sm={0}>
                <Nav
                    items={tabs}
                    active={state.currentTab}
                    onLogOut={() => {
                        auth.signOut().then(() => {
                            window.location.href = '/auth/login'
                        })
                    }}
                    orgName={currentOrg.orgName}
                    userFirstName={'Jane'}
                    userLastName={'Doe'}
                    logoUrl={'/logo-c.svg'}
                />
                </Col>
                <Col lg={6} md={8} sm={8}>
                <div className={'dg-dashboard-container'}>
                <TabTitle title={state.currentTab.toString()} handleSupportClick={() => changeTab('support')}/>

                <TabContainer activeKey={state.currentTab}>
                    <TabContent>
                        <TabPane eventKey={'Home'} id={'home'}>
                            <Home handleChange={changeTab}/>
                        </TabPane>
                        <TabPane eventKey={'Donations'} id={'donations'}>
                            <Donations handleChange={changeTab}/>
                        </TabPane>
                        <TabPane eventKey={'Campaigns'} id={'scantogive'}>
                            <ScanToGive handleChange={changeTab}/>
                        </TabPane>
                        <TabPane eventKey={'Organisation Settings'} id={'organisation-settings'}>
                            <OrgSettings />
                        </TabPane>
                        <TabPane eventKey={'Account Settings'} id={'account-settings'}>
                            <AccSettings />
                        </TabPane>
                    </TabContent>
                </TabContainer>
        </div>
                </Col>
            </Row>
            
            

            </div>
    );
}